import { Component, Inject } from '@angular/core';
import { TypeControleUpdate } from 'src/app/models/type-controle';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators, FormGroup } from '@angular/forms';

/**
 * Type Controle Form Component.
 */
@Component({
  selector: 'app-type-controle-form',
  templateUrl: './type-controle-form.component.html',
  styleUrls: ['./type-controle-form.component.css']
})
export class TypeControleFormComponent {

  nomFormControl = new FormControl('', [Validators.required]);
  dependantNiveauxFormControl = new FormControl('');

  typeControleForm: FormGroup = new FormGroup({
    nom: this.nomFormControl,
    dependantNiveaux: this.dependantNiveauxFormControl
  });

  constructor(public dialogRef: MatDialogRef<TypeControleFormComponent>,
    @Inject(MAT_DIALOG_DATA) public typeControleUpdate: TypeControleUpdate) {
    this.typeControleForm.patchValue({
      nom: typeControleUpdate.nom,
      dependantNiveaux: typeControleUpdate.dependantNiveaux
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.typeControleUpdate.nom = this.nomFormControl.value;
    this.typeControleUpdate.dependantNiveaux = this.dependantNiveauxFormControl.value;
  }

}
