import { Component, Inject } from '@angular/core';
import { FamilleUpdate } from 'src/app/models/famille-materiel';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormControl, FormGroup } from '@angular/forms';

/**
 * Famille Form Component.
 */
@Component({
  templateUrl: './famille-form.component.html',
  styleUrls: ['./famille-form.component.css']
})
export class FamilleFormComponent {

  famille: FamilleUpdate;

  nomFormControl = new FormControl('', [Validators.required]);
  codeDesignationFormControl = new FormControl('', [Validators.required]);
  codeModuleFormControl = new FormControl('', [Validators.required]);
  codeNiveauxFormControl = new FormControl('', [Validators.required]);
  aideUrlFormControl = new FormControl('', []);

  familleForm: FormGroup = new FormGroup({
    nom: this.nomFormControl,
    codeDesignation: this.codeDesignationFormControl,
    codeModule: this.codeModuleFormControl,
    codeNiveaux: this.codeNiveauxFormControl,
    aideUrl: this.aideUrlFormControl
  });

  constructor(public dialogRef: MatDialogRef<FamilleFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    const famille = data.familleUpdate;
    const readOnly = data.readOnly;
    this.famille = famille;

    if (readOnly) {
      this.nomFormControl.disable();
      this.codeDesignationFormControl.disable();
      this.codeModuleFormControl.disable();
      this.codeNiveauxFormControl.disable();
    }

    this.familleForm.patchValue({
      nom: famille.nom,
      codeDesignation: famille.codeDesignation,
      codeModule: famille.codeModule,
      codeNiveaux: famille.codeNiveaux,
      aideUrl: famille.aideUrl
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.famille.nom = this.nomFormControl.value;
    this.famille.codeDesignation = this.codeDesignationFormControl.value;
    this.famille.codeModule = this.codeModuleFormControl.value;
    this.famille.codeNiveaux = this.codeNiveauxFormControl.value;
    this.famille.aideUrl = this.aideUrlFormControl.value;
  }

}
