import { Component, OnInit } from '@angular/core';
import { UtilisateurService } from 'src/app/services/common/utilisateur.service';
import { Utilisateur } from 'src/app/models/utilisateur';

@Component({
  selector: 'app-utilisateur-detail',
  templateUrl: './utilisateur-detail.component.html',
  styleUrls: ['./utilisateur-detail.component.css']
})
export class UtilisateurDetailComponent implements OnInit {

  utilisateur: Utilisateur;

  constructor(private utilisateurService: UtilisateurService) { }

  ngOnInit() {
    this.utilisateur = this.utilisateurService.selectedUtilisateurSubject.value;
  }

}
