import { Component, Input } from '@angular/core';
import { StatutControleType } from 'src/app/models/point-de-controle';

/**
 * Composant d'affichage de l'enum StatutControleType.
 */
@Component({
  selector: 'app-statut-controle-type',
  template: `
  <ng-container [ngSwitch]="key">
    <ng-container i18n *ngSwitchCase="statutControleType.INIT">Initialisé</ng-container>
    <ng-container i18n *ngSwitchCase="statutControleType.OK">OK</ng-container>
    <ng-container i18n *ngSwitchCase="statutControleType.NON_CONTROLE">Non controlé</ng-container>
    <ng-container i18n *ngSwitchCase="statutControleType.NON_VALIDE">Non validé</ng-container>
  </ng-container>
  `,
})
export class StatutControleTypeComponent {

  // enum has to be accessed through class field
  statutControleType = StatutControleType;

  @Input()
  key: StatutControleType;
}