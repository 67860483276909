import { BehaviorSubject, Observable } from 'rxjs';
import { Photo } from 'src/app/models/photo';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

/**
 * Classe abstraite Photo Service.
 */
export abstract class PhotoService {

    photoListSubject: BehaviorSubject<Photo[]> = new BehaviorSubject<Photo[]>([]);

    constructor(private http: HttpClient) { }

    /**
     * URL de l'api de base à appeler.
     */
    abstract getApi(): string;

    /**
     * Chargement d'une liste de photo.
     * @param resourceId id du controle / id du point de controle
     */
    getPhotoList(resourceId: number): Observable<Photo[]> {
        let api = this.getApi() + "/" + resourceId + "/photos";
        return this.http.get<Photo[]>(environment.baseUrl + api).pipe(
            map((photoList: Photo[]) => {
                this.photoListSubject.next(photoList);
                return photoList;
            })
        );
    }

    /**
     * Insertion d'une photo.
     * @param resourceId id du controle / id du point de controle
     * @param photo photo à ajouter
     */
    postPhoto(resourceId: number, photo: File): Observable<Photo> {
        let api = this.getApi() + "/" + resourceId + "/photos";
        const formData: FormData = new FormData();
        formData.append('photo', photo, photo.name);

        return this.http.post<Photo>(environment.baseUrl + api, formData).pipe(
            map((photo: Photo) => {
                let photoList = [];
                photoList = photoList.concat(this.photoListSubject.value);
                photoList.push(photo);
                this.photoListSubject.next(photoList);
                return photo;
            })
        );
    }

    /**
     * Suppression d'une photo.
     * @param idPhoto id de la photo à supprimer
     */
    deletePhoto(idPhoto: number): Observable<void> {
        let api = environment.photoApi + "/" + idPhoto;

        return this.http.delete<void>(environment.baseUrl + api).pipe(
            map(() => {
                let photoList = [];
                photoList = photoList.concat(this.photoListSubject.value.filter((photo: Photo) => photo.id !== idPhoto));
                this.photoListSubject.next(photoList);
            })
        );
    }

}