import { Injectable } from '@angular/core';
import { OrdreUpdate } from '../../models/ordre';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TypePointDeControle, TypePointDeControleUpdate } from '../../models/type-point-de-controle';
import { map } from 'rxjs/operators';
import { TypeRegroupementService } from './type-regroupement.service';
import { EntityListService } from './entity-list.service';

@Injectable({
  providedIn: 'root'
})
export class TypePointDeControleService extends EntityListService {

  constructor(private http: HttpClient, private typeRegroupementService: TypeRegroupementService) {
    super();
  }

  public patchOrdre(ordreUpdateList: OrdreUpdate[]): Observable<void> {
    return this.http.patch<void>(environment.baseUrl + environment.typePointDeControleApi, ordreUpdateList);
  }

  public put(typePointDeControleUpdate: TypePointDeControleUpdate): Observable<TypePointDeControle> {
    return this.http.put<TypePointDeControle>(environment.baseUrl + environment.typePointDeControleApi, typePointDeControleUpdate)
      .pipe(
        map((typePointDeControle: TypePointDeControle) => this.refreshItem(this.typeRegroupementService.typePointDeControleListSubject, typePointDeControle))
      );
  }

  public post(typePointDeControleUpdate: TypePointDeControleUpdate): Observable<TypePointDeControle> {
    return this.http.post<TypePointDeControle>(environment.baseUrl + environment.typePointDeControleApi, typePointDeControleUpdate)
      .pipe(
        map((typePointDeControle: TypePointDeControle) => this.addItem(this.typeRegroupementService.typePointDeControleListSubject, typePointDeControle))
      );
  }

  public delete(typePointDeControle: TypePointDeControle): Observable<TypePointDeControle> {
    return this.http.delete<TypePointDeControle>(environment.baseUrl + environment.typePointDeControleApi + "/" + typePointDeControle.id)
      .pipe(
        map((typePointDeControle: TypePointDeControle) => this.removeItem(this.typeRegroupementService.typePointDeControleListSubject, typePointDeControle))
      );
  }

}
