import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ControleService } from '../../../services/materiel/controle.service';
import { Controle } from '../../../models/controle';
import { PointDeControle, StatutControleType, PointDeControleRegroupement } from '../../../models/point-de-controle';
import { MatSelectionListChange } from '@angular/material/list';
import { ValidationService } from '../../../services/materiel/validation.service';
import { useAnimation, transition, trigger, style, state } from '@angular/animations';
import { slideIn, slideOut } from '../../../animations';
import { MatDialog } from '@angular/material';
import { PointDeControleDetailComponent } from '../point-de-controle-detail/point-de-controle-detail.component';
import { PhotoControleFormComponent } from '../photo-controle-form/photo-controle-form.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BlobService } from 'src/app/services/common/blob.service';
import { MaterielSecurite } from 'src/app/models/materiel-securite';

/**
 * Point de Controle List Component - Affichage / Validation des points de controles par regroupement.
 */
@Component({
  selector: 'app-point-de-controle-list',
  templateUrl: './point-de-controle-list.component.html',
  styleUrls: ['./point-de-controle-list.component.css'],
  animations: [
    trigger('slideInOut', [
      state('flyIn', style({ transform: 'translateX(0)' })),
      transition(':enter', [useAnimation(slideIn)]),
      transition(':leave', [useAnimation(slideOut)])
    ])
  ]
})
export class PointDeControleListComponent implements OnInit, OnDestroy {

  @Input()
  controle: Controle;

  @Input()
  materielSecurite: MaterielSecurite;

  pointDeControleRegroupementList: PointDeControleRegroupement[];

  checkList: number[] = [];

  commentaire: string;

  nbOK: number = 0;
  nbTotal: number = 0;

  pointDeControleMapSubs: Subscription;

  signature: Blob;

  constructor(private controleService: ControleService, private validationService: ValidationService,
    public dialog: MatDialog, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.commentaire = this.controle.commentaire;
    if (this.controle.signature) {
      this.signature = BlobService.getBlobFromBase64(this.controle.signature);
    }

    // Chargement des données
    this.controleService.loadPointDeControleRegroupementList(this.controle);
    this.pointDeControleMapSubs = this.controleService.pointDeControleRegroupementListSubject.subscribe(pointDeControleRegroupementList => {
      this.pointDeControleRegroupementList = pointDeControleRegroupementList;
      this.initCompteur();
    });
  }

  ngOnDestroy(): void {
    this.pointDeControleMapSubs.unsubscribe();
  }

  onSelection(event: MatSelectionListChange) {
    let pointDeControle: PointDeControle = event.option.value;
    if (event.option.selected) {
      pointDeControle.statut = StatutControleType.OK;
    } else {
      pointDeControle.statut = StatutControleType.NON_VALIDE;
    }
    this.initCompteur();
  }

  onSave() {
    let pointDeControleList = [].concat.apply([], Array.from(this.pointDeControleRegroupementList.map(pointDeControleRegroupementList => pointDeControleRegroupementList.pointDeControleList)));
    this.validationService.postValidation(this.controle.id, this.commentaire, pointDeControleList, this.signature)
      .subscribe(() => this.router.navigate(['../../'], { relativeTo: this.route }));
  }

  onBack() {
    let pointDeControleList = [].concat.apply([], Array.from(this.pointDeControleRegroupementList.map(pointDeControleRegroupementList => pointDeControleRegroupementList.pointDeControleList)));
    this.validationService.postPreValidation(this.controle.id, this.commentaire, pointDeControleList, this.signature)
      .subscribe(() => this.router.navigate(['../../'], { relativeTo: this.route }));
  }

  onEdit(pointDeControle: PointDeControle) {
    const dialogRef = this.dialog.open(PointDeControleDetailComponent, {
      height: '90vh',
      width: '90vw',
      data: pointDeControle,
      panelClass: 'full-screen-modal'
    });

    dialogRef.afterClosed().subscribe(() => {
      pointDeControle.commentaire = dialogRef.componentInstance.pointDeControle.commentaire;
    });
  }

  onViewPhoto() {
    this.dialog.open(PhotoControleFormComponent, {
      height: '90vh',
      width: '90vw',
      data: this.controle,
      panelClass: 'full-screen-modal'
    });
  }

  private initCompteur() {
    let nbOK = 0;
    let nbTotal = 0;

    this.pointDeControleRegroupementList.forEach(pointDeControleRegroupement => {
      pointDeControleRegroupement.pointDeControleList.forEach((pointDeControle: PointDeControle) => {
        pointDeControle.statut === StatutControleType.OK ? nbOK++ : '';
        nbTotal++;
      });
    });
    this.nbOK = nbOK;
    this.nbTotal = nbTotal;
  }

}