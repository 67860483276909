import { Component, OnInit } from '@angular/core';
import { MaterielSecuriteService } from 'src/app/services/materiel/materiel-securite.service';
import * as moment from 'moment';

/**
 * Matériel Notification Component - Affichage des notifications.
 */
@Component({
  selector: 'app-materiel-notification-list',
  templateUrl: './materiel-notification-list.component.html',
  styleUrls: ['./materiel-notification-list.component.css']
})
export class MaterielNotificationListComponent implements OnInit {

  notificationList: Notification[];

  moment = moment;

  constructor(private materielSecuriteService: MaterielSecuriteService) { }

  ngOnInit() {
    this.materielSecuriteService.notificationListSubject.subscribe((notificationList: Notification[]) => this.notificationList = notificationList);
  }

}
