import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Loading Service.
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  /** True si un appel HTTP est en cours */
  public isLoading = new BehaviorSubject<boolean>(false);
  
  constructor() { }

  startLoading() {
    this.isLoading.next(true);
  }
  
  endLoading() {
    this.isLoading.next(false);
  }
}
