// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prd.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Configuration Cognito Open id accessible via l'url : https://cognito-idp.<region>.amazonaws.com/<poolId>/.well-known/openid-configuration
  // Par exemple : https://cognito-idp.eu-west-3.amazonaws.com/eu-west-3_OS4RmSZGB/.well-known/openid-configuration
  clientId: "6afnmi30idfp1o1a3m7beg7he",
  loginUrl: 'https://lguserpoolrec.auth.eu-west-3.amazoncognito.com/oauth2/authorize',
  issuer: 'https://cognito-idp.eu-west-3.amazonaws.com/eu-west-3_OS4RmSZGB',
  logoutUrl: 'https://lguserpoolrec.auth.eu-west-3.amazoncognito.com/logout',
  tokenEndpoint: 'https://lguserpoolrec.auth.eu-west-3.amazoncognito.com/oauth2/token',
  userinfoEndpoint: 'https://lguserpoolrec.auth.eu-west-3.amazoncognito.com/oauth2/userInfo',
  jwks: {
    "keys": [
      {
        "alg": "RS256",
        "e": "AQAB",
        "kid": "8eVesX8vipEvtoRH+sg/W0IbZFgsUTKsJs+JCc7chkY=",
        "kty": "RSA",
        "n": "yKdLoDXXdJraaJktxle2No-PLERrStMU9IPU3Gcb3fZ3fxnItyhrhpgVeGcr8RWnEFu1OAriWi3J9d7BxCt6QabWtNJ1YwzMTTVWFojlVLbRZwcnNHXoEt1bcUJHbCh3CJTudtc4dzK-d8sNCXfdGfyi5Wg20Ix7EWpjeL2rSSkilZmFJcfZ9_ZcH1bKjDTNrsXeT84oyPkf4_pllX_SjWai7zuKajrvOekm4NTTD8xMmcuz-ncPNA8_Qq6xetT8zqMObazpuzjlETa4-OAZbuiIPP1ehVbpERSURnkcDeyACTYaM4NDj_QuW_koSwf5sjitOR3QlW1ziZScZ-Zw6w",
        "use": "sig"
      },
      {
        "alg": "RS256",
        "e": "AQAB",
        "kid": "hk+Zxb1F/VjB8sRC/SmxYyfEi5bP98YvTLxnkvRhMVU=",
        "kty": "RSA",
        "n": "x9nnT0yEvFIGKLeZh9vH6p4T_jxrvOFJIKDRipVlJ6ig2rK8poRcrLCN6R5U0kCUt0vtK6k9096ya83YA8jKN82-m0Qv_7ItrFMUr5hIksLybPSiBb25zR3lQTEvtyhRmRw0FGvykq0NYIjp1buaAzp9aaMlSEn_oLlQGGRt4LAteIuY3IeQ6k8mebPA61jmChkwsK-u7rX00ArwmCwgICZ2YdmXnJFXSoqe6YnLTCfWn9cwxJe7TU1cCy9vfYAAVNDRMQM1_n699xJ1a_4gYPkLa0FDf3f1q0KzZwLpCXmU10FBbQE7_6q5khiOGGlfAB4ekfAfPDTQo3mmKdHw4Q",
        "use": "sig"
      }
    ]
  },
  baseUrl: "https://automat-rec.leon-grosse.fr/aautocontrole-materiel-api-rest",
  plansDriveBaseUrl: "https://drive.google.com/uc?id=:idDrive",
  controleByMaterielIdListApi: "/materielSecurites/controles/:idMateriel",
  typeRegroupementAffaireListApi: "/data-rest/typeControleAffaires/:idTypeControleAffaire/typeRegroupementAffaires",
  pointDeControleListApi: "/controles/:idControle/typeRegroupements/:idTypeRegroupementAffaire/pointDeControles",
  controleApi: "/controles",
  materielNotificationListApi: "/materielSecurites/notifications/:idMateriel",
  familleMaterielTypeControleApi: "/familleMateriels/:idFamille/typeControles",
  familleMaterielApi: "/familleMateriels",
  typeControleApi: "/typeControles",
  typeRegroupementApi: "/typeRegroupements",
  typePointDeControleApi: "/typePointDeControles",
  filiereApi: "/filieres",
  utilisateurApi: "/utilisateurs",
  pointDeControleApi: "/pointDeControles",
  photoApi: "/photos",
  affaireApi: "/affaires",
  planApi: "/plans/:maquetteNumeriqueCode",
  materielSecuriteApi: "/materielSecurites"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
