import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ControleFiltre } from 'src/app/models/controle';
import { FamilleService } from 'src/app/services/admin/famille.service';
import { FamilleCount } from 'src/app/models/famille-materiel';

@Component({
  selector: 'app-controle-export-filtre',
  templateUrl: './controle-export-filtre.component.html',
  styleUrls: ['./controle-export-filtre.component.css']
})
export class ControleExportFiltreComponent implements OnInit {

  @Output()
  onControleFiltreChange: EventEmitter<ControleFiltre> = new EventEmitter<ControleFiltre>();

  familleList: FamilleCount[];

  codeMaquette = new FormControl('', []);
  nomAffaire = new FormControl('', []);
  statut = new FormControl('', []);
  designation = new FormControl('', []);
  nomControle = new FormControl('', []);
  idFamille = new FormControl('', []);

  controleFiltreForm: FormGroup = new FormGroup({
    codeMaquette: this.codeMaquette,
    nomAffaire: this.nomAffaire,
    statut: this.statut,
    designation: this.designation,
    nomControle: this.nomControle,
    idFamille: this.idFamille
  });

  constructor(private familleService: FamilleService) { }

  ngOnInit() {
    this.familleService.familleListSubject.subscribe(familleList => {
      if (familleList.length === 0) {
        this.familleService.getFamilleCountList().subscribe();
      } else {
        this.familleList = familleList;
      }
    });
  }

  onSubmit() {
    const controleFiltre = new ControleFiltre();
    controleFiltre.codeMaquette = this.codeMaquette.value;
    controleFiltre.nomAffaire = this.nomAffaire.value;
    controleFiltre.statut = this.statut.value ? this.statut.value : undefined;
    controleFiltre.designation = this.designation.value;
    controleFiltre.nomControle = this.nomControle.value;
    controleFiltre.idFamille = this.idFamille.value ? this.idFamille.value : undefined;
    this.onControleFiltreChange.next(controleFiltre);
  }

}
