import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import imageCompression from 'browser-image-compression';

/**
 * Input Photo Component - input d'enregistrement/affichage des photos.
 */
@Component({
  selector: 'app-input-photo',
  templateUrl: './input-photo.component.html',
  styleUrls: ['./input-photo.component.css']
})
export class InputPhotoComponent implements OnInit, OnChanges {

  @Input()
  uploadedFile: File;

  @Output()
  uploadedFileChange: EventEmitter<File> = new EventEmitter<File>();

  @Output()
  onValidatedUpload: EventEmitter<File> = new EventEmitter<File>();

  imagePreview: string | ArrayBuffer;

  enablePreview: boolean = false;

  options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    onProgress: function () { }
  }

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.imagePreview = undefined;
  }

  fileInputChange(event: any) {
    let files = event.target.files;

    if (files.length > 0) {

      const uncompressedFile = files[0];
      //console.log(uncompressedFile.size / 1024 / 1024 + " MB");
      imageCompression(uncompressedFile, this.options).then(compressedFile => {
        this.uploadedFile = compressedFile as File;
        //console.log(this.uploadedFile.size / 1024 / 1024 + " MB");

        this.uploadedFileChange.emit(this.uploadedFile);

        if (this.enablePreview) {
          this.preview(files);
        } else {
          this.validation();
        }
      });
    }
  }

  validation() {
    this.onValidatedUpload.emit(this.uploadedFile);
    this.imagePreview = undefined
  }

  preview(files: File[]): void {
    Array.from(files).forEach((photo: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result;
      };
      reader.readAsDataURL(photo);
    });
  }

}
