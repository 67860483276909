import { Component, OnInit, OnDestroy } from '@angular/core';
import { FamilleService } from 'src/app/services/admin/famille.service';
import { FamilleCount, FamilleUpdate, Famille } from 'src/app/models/famille-materiel';
import { FamilleFormComponent } from '../famille-form/famille-form.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

/**
 * Famille List Component.
 */
@Component({
  selector: 'app-famille-list',
  templateUrl: './famille-list.component.html',
  styleUrls: ['./famille-list.component.css']
})
export class FamilleListComponent implements OnInit, OnDestroy {

  familleCountList: FamilleCount[] = [];

  displayedColumns: string[] = ['nom', 'codeDesignation', 'codeModule', 'codeNiveaux', 'nombreMateriel', 'typesControles', 'aideUrl', 'actions'];

  familleListSubscription: Subscription;

  constructor(private familleService: FamilleService, public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.familleService.getFamilleCountList().subscribe((familleCountList: FamilleCount[]) => this.familleCountList = familleCountList);
    this.familleListSubscription = this.familleService.familleListSubject.subscribe((familleCountList: FamilleCount[]) => this.familleCountList = familleCountList);
  }

  ngOnDestroy(): void {
    this.familleListSubscription.unsubscribe();
  }

  openDialog(familleCount: FamilleCount): void {
    const dialogRef = this.dialog.open(FamilleFormComponent, {
      width: '500px',
      data: {
        readOnly: this.readOnly(familleCount),
        familleUpdate: FamilleUpdate.to(familleCount)
      }
    });

    dialogRef.afterClosed().subscribe((familleUpdate: FamilleUpdate) => {
      if (familleUpdate) {
        this.familleService.putFamille(familleUpdate).subscribe((updatedFamille: FamilleCount) => {
          this._snackBar.open("Famille " + updatedFamille.nom + " mise à jour", "OK");
        });
      }
    });
  }

  delete(familleCount: FamilleCount): void {
    this.familleService.deleteFamille(familleCount).subscribe((deletedFamille: FamilleCount) => {
      this._snackBar.open("Famille " + deletedFamille.nom + " supprimée", "OK");
    });
  }

  select(familleCount: FamilleCount): void {
    let famille = new Famille();
    famille.id = familleCount.id;
    famille.nom = familleCount.nom;

    this.familleService.selectedFamilleSubject.next(famille);
  }

  readOnly(familleCount: FamilleCount): boolean {
    return familleCount.nombreMateriel !== 0;
  }

}
