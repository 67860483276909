import { Famille } from './famille-materiel';
import { Filiere } from './utilisateur';

/** TypeControle. */
export interface TypeControle {
    id: number;
    nom: string;
    ordre: number;
    actif: boolean;
    dependantNiveaux: boolean;
    famille: Famille;
    filieres: Filiere[];
}

/** TypeControleUpdate. */
export class TypeControleUpdate {
    id: number;
    nom: string;
    ordre: number;
    dependantNiveaux: boolean;
    idFamille: number;

    constructor() { }

    static to(typeControle: TypeControle): TypeControleUpdate {
        let typeControleUpdate = new TypeControleUpdate();
        typeControleUpdate.id = typeControle.id;
        typeControleUpdate.nom = typeControle.nom;
        typeControleUpdate.ordre = typeControle.ordre;
        typeControleUpdate.dependantNiveaux = typeControle.dependantNiveaux;
        typeControleUpdate.idFamille = typeControle.famille.id;
        return typeControleUpdate;
    }

}