import { BehaviorSubject } from 'rxjs';

/**
 * Entity List Service.
 */
export class EntityListService {

    /**
     * Mise à jour d'une entité dans un Subject.
     * @param behaviorSubject
     * @param entityAction
     */
    protected refreshItem(behaviorSubject: BehaviorSubject<any>, entityAction: any): any {
        let entityList: any[] = [];
        entityList = entityList.concat(behaviorSubject.value);
        entityList = entityList.map(entity => (entity.id === entityAction.id) ? entityAction : entity);
        behaviorSubject.next(entityList);
        return entityAction;
    }

    /**
     * Ajout d'une entité dans un Subject.
     * @param behaviorSubject 
     * @param entityAction 
     */
    protected addItem(behaviorSubject: BehaviorSubject<any>, entityAction: any): any {
        let entityList: any[] = [];
        entityList = entityList.concat(behaviorSubject.value);
        entityList.push(entityAction);
        behaviorSubject.next(entityList);
        return entityAction;
    }

    /**
     * Suppression d'une entité dans un Subject.
     * @param behaviorSubject 
     * @param entityAction 
     */
    protected removeItem(behaviorSubject: BehaviorSubject<any>, entityAction: any): any {
        let entityList: any[] = [];
        entityList = entityList.concat(behaviorSubject.value);
        const entityIndex = entityList.findIndex(entity => entity.id === entityAction.id);
        entityList.splice(entityIndex, 1);

        behaviorSubject.next(entityList);

        return entityAction;
    }

}