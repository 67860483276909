import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Page } from 'src/app/models/page';
import { ControleDetail, ControleFiltre } from 'src/app/models/controle';
import { ControleExportService } from 'src/app/services/admin/controle-export.service';
import { PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import * as moment from 'moment';

/**
 * Controle Export List Component.
 */
@Component({
  selector: 'app-controle-export-list',
  templateUrl: './controle-export-list.component.html',
  styleUrls: ['./controle-export-list.component.css']
})
export class ControleExportListComponent implements OnChanges {

  moment = moment;

  @Input()
  controleFiltre: ControleFiltre;

  controleDetailPage: Page<ControleDetail> = new Page<ControleDetail>();

  selection = new SelectionModel<ControleDetail>(true, []);

  page: number = 0;

  size: number = 10;

  pageSizeOptions: number[] = [10, 25, 50, 100];

  displayedColumns: string[] = ['select', 'codeMaquette', 'nomAffaire', 'familleMateriel', 'designation', 'statutMateriel', 'nomControle', 'statut', 'par', 'date'];

  constructor(private controleExportService: ControleExportService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.load();
  }

  updatePage(pageEvent: PageEvent) {
    this.page = pageEvent.pageIndex;
    this.size = pageEvent.pageSize;
    this.load();
  }

  load() {
    this.selection.clear();
    this.controleExportService.getPage(this.page, this.size, this.controleFiltre).subscribe(controleDetailPage => this.controleDetailPage = controleDetailPage);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.controleDetailPage.content.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.controleDetailPage.content.forEach(row => this.selection.select(row));
  }

  export() {
    this.controleExportService.exportSelection(this._getControleDetailSelection());
  }

  private _getControleDetailSelection(): ControleDetail[] {
    return this.selection.selected;
  }

}
