import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ControleDetail, ControleIdList, ControleFiltre } from 'src/app/models/controle';
import { Observable } from 'rxjs';
import { Page } from 'src/app/models/page';
import { saveAs } from 'file-saver';

/**
 * Controle Export Service.
 */
@Injectable({
  providedIn: 'root'
})
export class ControleExportService {

  constructor(private http: HttpClient) { }

  getPage(page: number, size: number, controleFiltre: ControleFiltre): Observable<Page<ControleDetail>> {
    let filtre = "";

    if (controleFiltre) {
      filtre = "&codeMaquette={1}&nomAffaire={2}&statut={3}&designation={4}&nomControle={5}&idFamille={6}";
      filtre = filtre.replace("{1}", encodeURIComponent(controleFiltre.codeMaquette));
      filtre = filtre.replace("{2}", encodeURIComponent(controleFiltre.nomAffaire));
      filtre = filtre.replace("{3}", controleFiltre.statut ? controleFiltre.statut : "");
      filtre = filtre.replace("{4}", encodeURIComponent(controleFiltre.designation));
      filtre = filtre.replace("{5}", encodeURIComponent(controleFiltre.nomControle));
      filtre = filtre.replace("{6}", controleFiltre.idFamille ? controleFiltre.idFamille.toString() : "");
    }
    return this.http.get<Page<ControleDetail>>(environment.baseUrl + environment.controleApi + "?page=" + page + "&size=" + size + filtre);
  }

  exportSelection(controleDetailList: ControleDetail[]) {
    const idList = [];
    controleDetailList.map(controleDetail => idList.push(controleDetail.id));

    return this.http.post(environment.baseUrl + environment.controleApi + "/pdf", new ControleIdList(idList), {
      observe: 'response',
      responseType: 'blob'
    }).subscribe(response => {
      const filenameWrap = response.headers.get('content-disposition').split("filename=")[1];
      const filename = filenameWrap.substring(1, filenameWrap.length - 1);
      saveAs(response.body, filename);
    });
  }
}
