import { Component, OnInit, ViewChild, Input, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { TypeControleService } from 'src/app/services/admin/type-controle.service';
import { TypeControle, TypeControleUpdate } from 'src/app/models/type-controle';
import { Page } from 'src/app/models/page';
import { PageEvent } from '@angular/material/paginator';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable, MatSnackBar, MatDialog } from '@angular/material';
import { FamilleService } from 'src/app/services/admin/famille.service';
import { TypeControleFormComponent } from '../type-controle-form/type-controle-form.component';
import { Subscription } from 'rxjs';
import { OrdreUpdate } from 'src/app/models/ordre';
import { FiliereFormComponent } from '../filiere-form/filiere-form.component';

/**
 * Type Controle List Component.
 */
@Component({
  selector: 'app-type-controle-list',
  templateUrl: './type-controle-list.component.html',
  styleUrls: ['./type-controle-list.component.css']
})
export class TypeControleListComponent implements OnInit, AfterViewInit, OnDestroy {

  typeControlePageSubscription: Subscription;

  @ViewChild(MatTable, { static: false }) table: MatTable<TypeControle>;

  @ViewChild("table", { static: false, read: ElementRef }) tableElementRef: ElementRef;

  @Input()
  selectedFamilleId: number;

  typeControleList: TypeControle[] = [];

  page: number = 0;

  size: number = 10;

  totalElements: number = 0;

  pageSizeOptions: number[] = [5, 10, 25, 100];

  displayedColumns: string[] = ['ordre', 'nom', 'dependantNiveaux', 'famille', 'filieres', 'typesPointDeControles', 'actions'];

  constructor(private typeControleService: TypeControleService, private familleService: FamilleService, private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this.typeControleService.getTypeControlePage(this.selectedFamilleId, this.page, this.size).subscribe((typeControlePage: Page<TypeControle>) => this.initTable(typeControlePage));
    this.typeControlePageSubscription = this.typeControleService.typeControlePageSubject.subscribe((typeControlePage: Page<TypeControle>) => this.initTable(typeControlePage));
  }

  ngOnDestroy(): void {
    this.typeControlePageSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    // BUGFIX : https://github.com/angular/components/pull/18356
    setTimeout(() => {
      this.tableElementRef.nativeElement.click();
    }, 100);
  }

  updatePage(pageEvent: PageEvent) {
    this.page = pageEvent.pageIndex;
    this.size = pageEvent.pageSize;
    this.typeControleService.getTypeControlePage(this.selectedFamilleId, this.page, this.size).subscribe((typeControlePage: Page<TypeControle>) => this.initTable(typeControlePage));
  }

  dropTable(event: CdkDragDrop<TypeControle[]>) {
    const prevIndex = this.typeControleList.findIndex((d) => d === event.item.data);
    moveItemInArray(this.typeControleList, prevIndex, event.currentIndex);
    // Mise à jour des ordres
    this.typeControleList.forEach((typeControle: TypeControle, ordre: number) => typeControle.ordre = (ordre + (this.page * this.size)) + 1);
    let ordreUpdate = this.typeControleList.map((typeControle: TypeControle) => new OrdreUpdate(typeControle.id, typeControle.ordre));
    this.typeControleService.patchOrdre(ordreUpdate).subscribe(() => {
      this._snackBar.open("Ordre mis à jour", "OK");
    });
    this.table.renderRows();
  }

  openDialog(typeControle: TypeControle): void {
    const dialogRef = this.dialog.open(TypeControleFormComponent, {
      width: '500px',
      data: TypeControleUpdate.to(typeControle)
    });

    dialogRef.afterClosed().subscribe((typeControleUpdate: TypeControleUpdate) => {
      if (typeControleUpdate) {
        this.typeControleService.putTypeControle(typeControleUpdate).subscribe((typeControle: TypeControle) => {
          this._snackBar.open("Type de controle " + typeControle.nom + " mis à jour", "OK");
        });
      }
    });
  }

  openFiliereDialog(typeControle: TypeControle): void {
    const dialogRef = this.dialog.open(FiliereFormComponent, {
      width: '500px',
      data: typeControle.filieres
    });

    dialogRef.afterClosed().subscribe((filiereIdList: number[]) => {
      if (filiereIdList) {
        this.typeControleService.postFiliereList(typeControle.id, filiereIdList).subscribe(() => this._snackBar.open("Mise à jour des filières", "OK"));
      }
    });
  }

  delete(typeControle: TypeControle): void {
    this.typeControleService.deleteTypeControle(typeControle).subscribe((typeControle: TypeControle) => {
      this._snackBar.open("Type de controle " + typeControle.nom + " supprimé", "OK");
    });
  }

  select(typeControle: TypeControle): void {
    this.typeControleService.selectedTypeControleSubject.next(typeControle);
  }

  private initTable(typeControlePage: Page<TypeControle>): void {
    this.typeControleList = typeControlePage.content;
    this.totalElements = typeControlePage.totalElements;
  }

}
