import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { TypeRegroupementService } from 'src/app/services/admin/type-regroupement.service';
import { Subscription } from 'rxjs';
import { TypePointDeControle, TypePointDeControleUpdate } from 'src/app/models/type-point-de-controle';
import { TypePointDeControleService } from 'src/app/services/admin/type-point-de-controle.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar, MatDialog, MatTable } from '@angular/material';
import { TypePointDeControleFormComponent } from '../type-point-de-controle-form/type-point-de-controle-form.component';
import { TypeRegroupement } from 'src/app/models/type-regroupement';
import { OrdreUpdate } from 'src/app/models/ordre';

/**
 * Type Point de Controle List Component.
 */
@Component({
  selector: 'app-type-point-de-controle-list',
  templateUrl: './type-point-de-controle-list.component.html',
  styleUrls: ['./type-point-de-controle-list.component.css']
})
export class TypePointDeControleListComponent implements OnInit, OnDestroy {

  @ViewChild(MatTable, { static: false }) table: MatTable<TypeRegroupement>;

  @ViewChild("pointDeControleTable", { static: false, read: ElementRef }) tableElementRef: ElementRef;

  @Input()
  typeRegroupementId: number;

  typePointDeControleList: TypePointDeControle[];

  page: number = 0;

  size: number = 10;

  displayedColumns: string[] = ['ordre', 'nom', 'codeCaracteristique', 'actions'];

  typePointDeControleSubscription: Subscription;

  constructor(private typeRegroupementService: TypeRegroupementService, private typePointDeControleService: TypePointDeControleService,
    private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this.typePointDeControleSubscription = this.typeRegroupementService.typePointDeControleListSubject
      .subscribe((typePointDeControleList: TypePointDeControle[]) => {
        if (this.typeRegroupementService.selectedTypeRegroupementSubject.value
          && this.typeRegroupementId === this.typeRegroupementService.selectedTypeRegroupementSubject.value.id) {
          this.typePointDeControleList = typePointDeControleList;
        }
      });

    this.typeRegroupementService.selectedTypeRegroupementSubject.subscribe((typeRegroupement: TypeRegroupement) => {
      // BUGFIX : https://github.com/angular/components/pull/18356
      setTimeout(() => {
        this.tableElementRef.nativeElement.click();
      }, 100);
    });
  }

  ngOnDestroy(): void {
    this.typePointDeControleSubscription.unsubscribe();
  }

  openDialog(typePointDeControle: TypePointDeControle): void {
    const dialogRef = this.dialog.open(TypePointDeControleFormComponent, {
      width: '500px',
      data: TypePointDeControleUpdate.to(typePointDeControle)
    });

    dialogRef.afterClosed().subscribe((typePointDeControleUpdate: TypePointDeControleUpdate) => {
      if (typePointDeControleUpdate) {
        this.typePointDeControleService.put(typePointDeControleUpdate).subscribe((typePointDeControle: TypePointDeControle) => {
          this._snackBar.open("Type de point de controle " + typePointDeControle.nom + " mis à jour", "OK");
        });
      }
    });
  }

  dropTable(event: CdkDragDrop<TypePointDeControle[]>) {
    const prevIndex = this.typePointDeControleList.findIndex((d) => d === event.item.data);
    moveItemInArray(this.typePointDeControleList, prevIndex, event.currentIndex);

    // Mise à jour des ordres
    this.typePointDeControleList.forEach((typePointDeControle: TypePointDeControle, ordre: number) => typePointDeControle.ordre = ordre + 1);
    let ordreUpdate = this.typePointDeControleList.map((typePointDeControle: TypePointDeControle) => new OrdreUpdate(typePointDeControle.id, typePointDeControle.ordre));
    this.typePointDeControleService.patchOrdre(ordreUpdate).subscribe(() => {
      this._snackBar.open("Ordre mis à jour", "OK");
    });

    this.table.renderRows();
  }

  delete(typePointDeControle: TypePointDeControle): void {
    this.typePointDeControleService.delete(typePointDeControle).subscribe((typePointDeControle: TypePointDeControle) => {
      this._snackBar.open("Type de point de controle " + typePointDeControle.nom + " supprimé", "OK");
    });
  }

}
