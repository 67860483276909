import { Component } from '@angular/core';
import * as moment from 'moment';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from './auth-config';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UtilisateurService } from './services/common/utilisateur.service';
import { Utilisateur } from './models/utilisateur';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';

/** Définition de la locale globale. */
moment.locale('fr');

/**
 * App Component.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  /** Affichage des composants uniquement si l'utilisateur est connecté. */
  utilisateur: Utilisateur;

  constructor(private oauthService: OAuthService, private router: Router, private utilisateurService: UtilisateurService) {
    this.authentication();
  }

  /**
   * Gestion de l'authentification OAuth2 :
   * - Récupération du code d'authorisation (initImplicitFlow)
   * - Récupération de la token (tryLogin)
   * - Redirection vers la page précédente
   * Le rafraichissement de la token est gérer par la classe HttpRequestInterceptor.
   */
  authentication(): void {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.clientId = environment.clientId;
    this.oauthService.loginUrl = environment.loginUrl;
    this.oauthService.issuer = environment.issuer;
    this.oauthService.logoutUrl = environment.logoutUrl;
    this.oauthService.tokenEndpoint = environment.tokenEndpoint;
    this.oauthService.userinfoEndpoint = environment.userinfoEndpoint;
    this.oauthService.jwks = environment.jwks;

    // Use setStorage to use localStorage
    this.oauthService.setStorage(localStorage);

    this.oauthService.tryLogin().then(() => {
      if (!this.oauthService.hasValidIdToken()) {
        // Init Flow
        localStorage.setItem("redirectUrl", this.router.url);
        this.oauthService.initCodeFlow();
      } else {
        // OAuth2 Token OK, récupération de la session utilisateur à partir de la token
        this.utilisateurService.getUtilisateurConnecte(this.oauthService.getIdToken()).subscribe((utilisateur: Utilisateur) => {
          this.utilisateur = utilisateur;

          let redirectUrl = localStorage.getItem("redirectUrl");
          if (redirectUrl && redirectUrl !== "undefined" && redirectUrl !== this.router.url) {
            localStorage.setItem("redirectUrl", undefined);
            this.router.navigateByUrl(redirectUrl);
          }
        });
      }
    });
  }

}
