/** TypeRegroupement. */
export interface TypeRegroupement {
    id: number;
    nom: string;
    ordre: number;
    idTypeControle: number;
}

/** TypeRegroupementUpdate. */
export class TypeRegroupementUpdate {
    id: number;
    nom: string;
    ordre: number;
    idTypeControle: number;

    static to(typeRegroupement: TypeRegroupement): TypeRegroupementUpdate {
        let typeRegroupementUpdate = new TypeRegroupementUpdate();
        typeRegroupementUpdate.id = typeRegroupement.id;
        typeRegroupementUpdate.nom = typeRegroupement.nom;
        typeRegroupementUpdate.ordre = typeRegroupement.ordre;
        typeRegroupementUpdate.idTypeControle = typeRegroupement.idTypeControle;
        return typeRegroupement;
    }
}