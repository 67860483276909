import { Component, Inject } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { TypePointDeControleUpdate } from 'src/app/models/type-point-de-controle';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

/**
 * Type Point de Controle Form Component.
 */
@Component({
  selector: 'app-type-point-de-controle-form',
  templateUrl: './type-point-de-controle-form.component.html',
  styleUrls: ['./type-point-de-controle-form.component.css']
})
export class TypePointDeControleFormComponent {

  nomFormControl = new FormControl('', [Validators.required]);

  codeCaracteristiqueFormControl = new FormControl('', []);

  typePointDeControleForm: FormGroup = new FormGroup({
    nom: this.nomFormControl,
    codeCaracteristique: this.codeCaracteristiqueFormControl
  });

  constructor(public dialogRef: MatDialogRef<TypePointDeControleFormComponent>,
    @Inject(MAT_DIALOG_DATA) public typePointDeControleUpdate: TypePointDeControleUpdate) {
    this.typePointDeControleForm.patchValue({
      nom: typePointDeControleUpdate.nom,
      codeCaracteristique: typePointDeControleUpdate.codeCaracteristique
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.typePointDeControleUpdate.nom = this.nomFormControl.value;
    this.typePointDeControleUpdate.codeCaracteristique = this.codeCaracteristiqueFormControl.value;
  }

}
