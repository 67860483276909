import { Component, OnInit } from '@angular/core';
import { Utilisateur, DSI } from 'src/app/models/utilisateur';
import { UtilisateurService } from 'src/app/services/common/utilisateur.service';

/**
 * Admin View Component - Conteneur d'affichage de la page admin (incl. Sidenav).
 */
@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.css']
})
export class AdminViewComponent implements OnInit {

  isDsi: boolean;

  constructor(private utilisateurService: UtilisateurService) { }

  ngOnInit() {
    const utilisateur = this.utilisateurService.selectedUtilisateurSubject.value;
    this.isDsi = utilisateur.filiere && utilisateur.filiere.code == DSI
  }

}
