import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from "@angular/core";
import { Controle } from "../../../models/controle";
import { ControleService } from "../../../services/materiel/controle.service";
import { MaterielSecurite } from "../../../models/materiel-securite";
import * as moment from 'moment';
import { Utilisateur } from 'src/app/models/utilisateur';
import { UtilisateurService } from 'src/app/services/common/utilisateur.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

/**
 * Controle List Component - liste des controles actifs.
 */
@Component({
  selector: "app-controle-list",
  templateUrl: "./controle-list.component.html",
  styleUrls: ["./controle-list.component.css"]
})
export class ControleListComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  materielSecurite: MaterielSecurite;

  controleList: Controle[];

  utilisateur: Utilisateur;

  moment = moment;

  controleListSubs: Subscription;

  constructor(private controleService: ControleService, private utilisateurService: UtilisateurService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.utilisateur = this.utilisateurService.selectedUtilisateurSubject.value;
    this.controleListSubs = this.controleService.controleList.subscribe(
      (controleList: Controle[]) => this.controleList = controleList
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.controleService.loadControleByMaterielIdList(this.materielSecurite.id);
    this.controleService.selectedControleSubject.next(undefined);
  }

  ngOnDestroy(): void {
    this.controleListSubs.unsubscribe();
  }

  displayPointDeControleList(controle: Controle) {
    this.controleService.select(controle);
    this.router.navigate(['controle/' + controle.id], { relativeTo: this.route });
  }

}
