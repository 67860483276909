import { Component, OnInit, Input } from '@angular/core';
import { MaterielSecuriteService } from 'src/app/services/materiel/materiel-securite.service';
import { MaterielSecuriteItem } from 'src/app/models/materiel-securite';
import { Page } from 'src/app/models/page';
import { MatSidenav } from '@angular/material';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-materiel-securite-item-list',
  templateUrl: './materiel-securite-item-list.component.html',
  styleUrls: ['./materiel-securite-item-list.component.css']
})
export class MaterielSecuriteItemListComponent implements OnInit {

  @Input()
  sidenav: MatSidenav;

  materielSecuriteItemList: MaterielSecuriteItem[] = [];

  affaireId: number;

  page: number = 0;
  size: number = 15;
  last: boolean = false;

  constructor(private materielSecuriteService: MaterielSecuriteService) { }

  ngOnInit() {
    const materielSecuriteSubs = this.materielSecuriteService.materielSecuriteSubject.subscribe(materielSecurite => {
      if (materielSecurite) {
        materielSecuriteSubs.unsubscribe();
        this.affaireId = materielSecurite.affaire.id;
        this.materielSecuriteService.getMaterielSecuriteItemList(materielSecurite.affaire.id, this.page, this.size)
          .subscribe(materielSecuriteItemPage => this.updateList(materielSecuriteItemPage));
      }
    });

  }

  getRouterLink(materielSecuriteItem: MaterielSecuriteItem): string {
    return "/materiel-securite/" + materielSecuriteItem.codeAffaire + "/" + materielSecuriteItem.codePhaseProjet + "/" + materielSecuriteItem.codeBible
      + "/" + materielSecuriteItem.designation + "/" + materielSecuriteItem.module;
  }

  nextPage(): void {
    this.materielSecuriteService.getMaterielSecuriteItemList(this.affaireId, this.page + 1, this.size)
      .subscribe(materielSecuriteItemPage => this.updateList(materielSecuriteItemPage));
  }

  private updateList(materielSecuriteItemPage: Page<MaterielSecuriteItem>): void {
    this.last = materielSecuriteItemPage.last;
    this.page = materielSecuriteItemPage.pageable.pageNumber;
    this.materielSecuriteItemList = this.materielSecuriteItemList.concat(materielSecuriteItemPage.content);
  }

}
