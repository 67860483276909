import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { Photo, PhotoAffichage } from 'src/app/models/photo';
import { MatSnackBar } from '@angular/material';

/**
 * Photo List Component.
 */
@Component({
  selector: 'app-photo-list',
  templateUrl: './photo-list.component.html',
  styleUrls: ['./photo-list.component.css']
})
export class PhotoListComponent implements OnChanges {

  @Input()
  photoList: Photo[];

  @Output()
  onDelete: EventEmitter<Photo> = new EventEmitter<Photo>();

  photoAffichageList: PhotoAffichage[];

  constructor(private _snackBar: MatSnackBar) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.photoAffichageList = this.photoList.map(photo => this.toPhotoAffichage(photo));
  }

  delete(photoAffichage: PhotoAffichage) {
    this.onDelete.emit(this.photoList.filter(photo => photo.id === photoAffichage.id)[0]);
  }

  toPhotoAffichage(photo: Photo): PhotoAffichage {
    const photoAffichage = new PhotoAffichage();
    photoAffichage.id = photo.id;
    photoAffichage.nom = photo.nom;
    photoAffichage.image = 'data:image/png;base64,' + photo.contenu;

    return photoAffichage;
  }

}
