/** Utilisateur. */
export interface Utilisateur {
    id: number;
    loginAd: string;
    prenom: string;
    nom: string;
    dateSortie: Date;
    dateModification: Date;
    nomEmploi: string;
    filiere: Filiere;
}

/** Filiere. */
export interface Filiere {
    id: number;
    code: string;
    nom: string;
}

export const DSI = "EC18";