import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MaterielSecurite, Chantier, MaterielSecuriteItem } from '../../models/materiel-securite';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from 'src/app/models/page';
import { Plan } from 'src/app/models/plan';

/**
 * Materiel Securite Service.
 */
@Injectable({
  providedIn: 'root'
})
export class MaterielSecuriteService {

  /** Matériel de sécurité sélectionné */
  materielSecuriteSubject: BehaviorSubject<MaterielSecurite> = new BehaviorSubject<MaterielSecurite>(undefined);

  /** Liste des notifications */
  notificationListSubject: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);

  /** Liste des chantiers */
  chantierListSubject: BehaviorSubject<Chantier[]> = new BehaviorSubject<Chantier[]>(undefined);
  
  /** Liste des plans */
  planListSubject: BehaviorSubject<Plan[]> = new BehaviorSubject<Plan[]>([]);

  constructor(private http: HttpClient) { }

  loadMaterielSecurite(codeAffaire: string, codePhase: string, codeBible: string, designation: string, module: string): Subscription {
    let api = environment.materielSecuriteApi + "/" + codeAffaire + "/" + codePhase + "/" + codeBible + "/" + designation + "/" + module;

    return this.http.get<MaterielSecurite>(environment.baseUrl + api)
      .subscribe((materielSecurite: MaterielSecurite) => {
        this.materielSecuriteSubject.next(materielSecurite);
        this.loadNotifications(materielSecurite.id);
        this.loadPlans(materielSecurite.codeMaquette);        
      });
  }

  loadNotifications(materielSecuriteId: number): Subscription {
    let api = environment.materielNotificationListApi.replace(":idMateriel", "" + materielSecuriteId);
    return this.http.get<Notification[]>(environment.baseUrl + api)
      .subscribe((notificationList: Notification[]) => this.notificationListSubject.next(notificationList));
  }
  
  loadPlans(maquetteNumeriqueCode: string): Subscription {
    let api = environment.planApi.replace(":maquetteNumeriqueCode", "" + maquetteNumeriqueCode);
    return this.http.get<Plan[]>(environment.baseUrl + api)
      .subscribe((planList: Plan[]) => this.planListSubject.next(planList));
  }

  getChantiers(affaireId: number): Observable<Chantier[]> {
    let api = environment.affaireApi + "/" + affaireId + "/chantiers";
    return this.http.get<Chantier[]>(environment.baseUrl + api).pipe(
      map(chantierList => {
        this.chantierListSubject.next(chantierList);
        return chantierList;
      })
    );
  }

  getMaterielSecuriteItemList(affaireId: number, page: number, size: number): Observable<Page<MaterielSecuriteItem>> {
    return this.http.get<Page<MaterielSecuriteItem>>(environment.baseUrl + environment.materielSecuriteApi + "/affaire/" + affaireId + "?page=" + page + "&size=" + size);
  }

}
