import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpEvent
} from '@angular/common/http';
import { finalize, catchError } from 'rxjs/operators';
import { Observable, throwError, from } from 'rxjs';
import { LoadingService } from './services/loading.service';
import { ErrorService } from './services/error.service';
import { environment } from 'src/environments/environment';
import { OAuthService, OAuthEvent } from 'angular-oauth2-oidc';

/**
 * Http Loading Interceptor.
 */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    private totalRequests = 0;

    constructor(private loadingService: LoadingService, private errorService: ErrorService, private oauthService: OAuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handle(req, next));
    }

    private async handle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
        this.totalRequests++;
        this.loadingService.startLoading();

        // Envoi des credentials (cookie) uniquement pour les APIs du backend
        if (req.url.match(environment.baseUrl)) {
            // Rafrachissement de la token si elle a expiré
            await this.refreshToken();
            if (this.oauthService.hasValidIdToken) {
                let idToken: string = localStorage.getItem("id_token");
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ` + idToken
                    },
                    withCredentials: true
                });
            }
            else {
                req = req.clone({
                    withCredentials: true
                });
            }
        }

        return next.handle(req).pipe(
            catchError((error) => {
                this.errorService.errorSubject.next(error);
                return throwError(error);
            }),
            finalize(() => {
                this.totalRequests--;
                if (this.totalRequests === 0) {
                    this.loadingService.endLoading();
                }
            })).toPromise();
    }

    private refreshToken(): Promise<void> {
        return new Promise<void>((resolve) => {
            if ((+ new Date()) >= this.oauthService.getAccessTokenExpiration() && this.oauthService.getRefreshToken()) {
                this.oauthService.refreshToken().then((oauthEvent: OAuthEvent) => {
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

}