import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { PointDeControle, PointDeControleUpdate } from 'src/app/models/point-de-controle';
import { FormControl, FormGroup } from '@angular/forms';
import { PointDeControleService } from 'src/app/services/materiel/point-de-controle.service';
import { PointDeControlePhotoService } from 'src/app/services/materiel/photo/point-de-controle-photo.service';

/**
 * Point de Controle Detail Component.
 */
@Component({
  selector: 'app-point-de-controle-detail',
  templateUrl: './point-de-controle-detail.component.html',
  styleUrls: ['./point-de-controle-detail.component.css']
})
export class PointDeControleDetailComponent {

  commentaireFormControl = new FormControl('', []);


  pointDeControleForm: FormGroup = new FormGroup({
    commentaire: this.commentaireFormControl
  });

  constructor(public dialogRef: MatDialogRef<PointDeControleDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public pointDeControle: PointDeControle, private pointDeControleService: PointDeControleService,
    private _snackBar: MatSnackBar, public pointDeControlePhotoService: PointDeControlePhotoService) {
    this.pointDeControleForm.patchValue({
      commentaire: pointDeControle.commentaire
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCommentaireSubmit(): void {
    this.pointDeControleService.putPointDeControle(new PointDeControleUpdate(this.pointDeControle.id, this.commentaireFormControl.value))
      .subscribe((pointDeControle: PointDeControle) => {
        this.pointDeControle = pointDeControle;
        this._snackBar.open("Commentaire mis à jour", "OK");
      });
  }

}
