import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../../services/loading.service';

/**
 * Loading Bar Component.
 */
@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.css']
})
export class LoadingBarComponent implements OnInit {

  isLoading: boolean;

  constructor(private loadingService: LoadingService) { }

  ngOnInit() {
    this.loadingService.isLoading.subscribe((isLoading: boolean) => setTimeout(() => this.isLoading = isLoading));
  }

}
