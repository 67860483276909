import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PhotoService } from './photo.service';
import { HttpClient } from '@angular/common/http';

/**
 * Photo Service.
 */
@Injectable({
  providedIn: 'root'
})
export class ControlePhotoService extends PhotoService {

  constructor(http: HttpClient) {
    super(http);
  }

  getApi(): string {
    return environment.controleApi;
  }

}
