import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Filiere } from 'src/app/models/utilisateur';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FiliereService {

  constructor(private http: HttpClient) { }

  public get() {
    return this.http.get<Filiere[]>(environment.baseUrl + environment.filiereApi);
  }
}
