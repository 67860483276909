import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { TypeControleService } from 'src/app/services/admin/type-controle.service';
import { Page } from 'src/app/models/page';
import { TypeRegroupement, TypeRegroupementUpdate } from 'src/app/models/type-regroupement';
import { PageEvent, MatTable, MatSnackBar, MatDialog } from '@angular/material';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { TypeRegroupementService } from 'src/app/services/admin/type-regroupement.service';
import { OrdreUpdate } from 'src/app/models/ordre';
import { TypeRegroupementFormComponent } from '../type-regroupement-form/type-regroupement-form.component';
import { Subscription } from 'rxjs';
import { TypePointDeControleFormComponent } from '../type-point-de-controle-form/type-point-de-controle-form.component';
import { TypePointDeControleUpdate, TypePointDeControle } from 'src/app/models/type-point-de-controle';
import { TypePointDeControleService } from 'src/app/services/admin/type-point-de-controle.service';

/**
 * Type Regroupement List Component.
 */
@Component({
  selector: 'app-type-regroupement-list',
  templateUrl: './type-regroupement-list.component.html',
  styleUrls: ['./type-regroupement-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms ease-in-out')),
    ]),
  ]
})
export class TypeRegroupementListComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  selectedTypeControleId: number;

  @ViewChild(MatTable, { static: false }) table: MatTable<TypeRegroupement>;

  @ViewChild("table", { static: false, read: ElementRef }) tableElementRef: ElementRef;

  typeRegroupementPage: Page<TypeRegroupement>;

  expandedElement: TypeRegroupement | null;

  page: number = 0;

  size: number = 10;

  displayedColumns: string[] = ['ordre', 'nom', 'actions'];

  typeRegroupementSubscription: Subscription;

  constructor(private typeControleService: TypeControleService, private typeRegroupementService: TypeRegroupementService,
    private _snackBar: MatSnackBar, public dialog: MatDialog, private typePointDeControleService: TypePointDeControleService) { }

  ngOnInit() {
    this.typeControleService.getTypeRegroupementByTypeControlePage(this.selectedTypeControleId, this.page, this.size).subscribe();
    this.typeRegroupementSubscription = this.typeControleService.typeRegroupementPageSubject.subscribe((typeRegroupementPage: Page<TypeRegroupement>) => this.typeRegroupementPage = typeRegroupementPage);
  }

  ngOnDestroy(): void {
    this.typeRegroupementSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    // BUGFIX : https://github.com/angular/components/pull/18356
    setTimeout(() => {
      this.tableElementRef.nativeElement.click();
    }, 100);
  }

  updatePage(pageEvent: PageEvent) {
    this.page = pageEvent.pageIndex;
    this.size = pageEvent.pageSize;
    this.typeControleService.getTypeRegroupementByTypeControlePage(this.selectedTypeControleId, this.page, this.size).subscribe();
  }

  openDialog(typeRegroupement: TypeRegroupement): void {
    const dialogRef = this.dialog.open(TypeRegroupementFormComponent, {
      width: '500px',
      data: TypeRegroupementUpdate.to(typeRegroupement)
    });

    dialogRef.afterClosed().subscribe((typeRegroupementUpdate: TypeRegroupementUpdate) => {
      if (typeRegroupementUpdate) {
        this.typeRegroupementService.putTypeRegroupement(typeRegroupementUpdate).subscribe((typeRegroupement: TypeRegroupement) => {
          this._snackBar.open("Type de regroupement " + typeRegroupement.nom + " mis à jour", "OK");
        });
      }
    });
  }

  openTypePointDeControleDialog(typeRegroupement: TypeRegroupement): void {
    let typePointDeControleUpdate = new TypePointDeControleUpdate();

    typePointDeControleUpdate.idTypeRegroupement = typeRegroupement.id;
    const dialogRef = this.dialog.open(TypePointDeControleFormComponent, {
      width: '500px',
      data: typePointDeControleUpdate
    });

    dialogRef.afterClosed().subscribe((typePointDeControleUpdate: TypePointDeControleUpdate) => {
      if (typePointDeControleUpdate) {
        this.typePointDeControleService.post(typePointDeControleUpdate).subscribe((typePointDeControle: TypePointDeControle) => {
          this._snackBar.open("Type de point de controle " + typePointDeControle.nom + " créé", "OK");
        });
      }
    });
  }

  delete(typeRegroupement: TypeRegroupement): void {
    this.typeRegroupementService.deleteTypeRegroupement(typeRegroupement).subscribe((typeRegroupement: TypeRegroupement) => {
      this._snackBar.open("Type de regroupement " + typeRegroupement.nom + " supprimé", "OK");
    });
  }

  expand(typeRegroupement: TypeRegroupement): void {
    this.expandedElement = this.expandedElement === typeRegroupement ? null : typeRegroupement;
    if (this.expandedElement) {
      this.typeRegroupementService.getTypePointDeControleList(typeRegroupement.id).subscribe();
      this.typeRegroupementService.selectedTypeRegroupementSubject.next(typeRegroupement);
    }
  }

  dropTable(event: CdkDragDrop<TypeRegroupement[]>) {
    const prevIndex = this.typeRegroupementPage.content.findIndex((d) => d === event.item.data);
    moveItemInArray(this.typeRegroupementPage.content, prevIndex, event.currentIndex);

    // Mise à jour des ordres
    this.typeRegroupementPage.content.forEach((typeRegroupement: TypeRegroupement, ordre: number) => typeRegroupement.ordre = (ordre + (this.page * this.size)) + 1);
    let ordreUpdate = this.typeRegroupementPage.content.map((typeRegroupement: TypeRegroupement) => new OrdreUpdate(typeRegroupement.id, typeRegroupement.ordre));
    this.typeRegroupementService.patchOrdre(ordreUpdate).subscribe(() => {
      this._snackBar.open("Ordre mis à jour", "OK");
    });

    this.table.renderRows();
  }

}
