import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PointDeControleUpdate, PointDeControle } from 'src/app/models/point-de-controle';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * Point de Controle Service.
 */
@Injectable({
  providedIn: 'root'
})
export class PointDeControleService {

  constructor(private http: HttpClient) { }

  putPointDeControle(pointDeControleUpdate: PointDeControleUpdate): Observable<PointDeControle> {
    return this.http.put<PointDeControle>(environment.baseUrl + environment.pointDeControleApi, pointDeControleUpdate);
  }

}
