import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelectionListChange, MatListOption } from '@angular/material';
import { Filiere } from 'src/app/models/utilisateur';
import { FiliereService } from 'src/app/services/admin/filiere.service';

/**
 * Filiere Form Component.
 */
@Component({
  selector: 'app-filiere-form',
  templateUrl: './filiere-form.component.html',
  styleUrls: ['./filiere-form.component.css']
})
export class FiliereFormComponent {

  filiereList: Filiere[] = [];

  selectedFiliereIdList: number[] = [];

  constructor(public dialogRef: MatDialogRef<FiliereFormComponent>,
    @Inject(MAT_DIALOG_DATA) public selectedFiliereList: Filiere[], private filiereService: FiliereService) {
    filiereService.get().subscribe((filiereList: Filiere[]) => this.filiereList = filiereList);
  }

  onSelection(selectedOptions: MatListOption[]) {
    this.selectedFiliereIdList = selectedOptions.map(options => options.value.id);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isSelected(filiere: Filiere): boolean {
    return this.selectedFiliereList.filter((selectedFiliere: Filiere) => filiere.id === selectedFiliere.id).length > 0;
  }

}
