import { Component, Inject } from '@angular/core';
import { Controle } from 'src/app/models/controle';
import { ControlePhotoService } from 'src/app/services/materiel/photo/controle-photo.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Photo } from 'src/app/models/photo';

/**
 * Photo Controle Form Component.
 */
@Component({
  selector: 'app-photo-controle-form',
  templateUrl: './photo-controle-form.component.html',
  styleUrls: ['./photo-controle-form.component.css']
})
export class PhotoControleFormComponent {

  uploadedFile: File;

  photoList: Photo[] = [];

  uploadInProgress: boolean = false;

  constructor(public dialogRef: MatDialogRef<PhotoControleFormComponent>,
    @Inject(MAT_DIALOG_DATA) public controle: Controle, public photoService: ControlePhotoService, private _snackBar: MatSnackBar) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
