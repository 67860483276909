import { Component, AfterViewInit, ViewChild, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { BlobService } from 'src/app/services/common/blob.service';

@Component({
  selector: 'app-input-signature',
  templateUrl: './input-signature.component.html',
  styleUrls: ['./input-signature.component.css']
})
export class InputSignatureComponent implements AfterViewInit {

  @ViewChild(SignaturePad, { static: true }) signaturePad: SignaturePad;

  @ViewChild('signatureContainer', { static: true }) signatureContainer: ElementRef;

  @Input()
  signature: Blob;

  @Output()
  signatureChange: EventEmitter<Blob> = new EventEmitter<Blob>();

  signaturePadOptions: Object = {
    'minWidth': 2,
    'canvasWidth': '200',
    'canvasHeight': '149'
  };

  constructor() { }

  ngAfterViewInit() {
    if (this.signature) {
      let signaturePad = this.signaturePad;
      let signaturePadOptions = this.signaturePadOptions;

      var reader = new FileReader();
      reader.readAsDataURL(this.signature);
      reader.onloadend = function () {
        let base64 = "" + reader.result;
        signaturePad.fromDataURL(base64, signaturePadOptions);
      }
    }
    this.signaturePadOptions['canvasWidth'] = this.signatureContainer.nativeElement.offsetWidth - 1;
    this.signaturePad.set('canvasWidth', this.signatureContainer.nativeElement.offsetWidth - 1);
    this.signaturePad.clear();
  }

  drawComplete() {
    this.signature = this.getBlob();
    this.signatureChange.emit(this.signature);
  }

  reset() {
    this.signaturePad.clear();
  }

  getBlob(): Blob {
    return BlobService.getBlobFromDataURL(this.signaturePad.toDataURL('image/png'));
  }
}
