/** Page<T>. */
export class Page<T> {
    content: T[];
    totalElements: number;
    totalPages: number;
    size: number;
    numberOfElements: number;
    pageable: Pageable;
    last: boolean;

    constructor() {

    }
}

/** Pageable. */
export interface Pageable {
    pageSize: number;
    pageNumber: number;
}