import { Component, Inject } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TypeRegroupementUpdate } from 'src/app/models/type-regroupement';

/**
 * Type Regroupement Form Component.
 */
@Component({
  selector: 'app-type-regroupement-form',
  templateUrl: './type-regroupement-form.component.html',
  styleUrls: ['./type-regroupement-form.component.css']
})
export class TypeRegroupementFormComponent {

  nomFormControl = new FormControl('', [Validators.required]);

  typeRegroupementForm: FormGroup = new FormGroup({
    nom: this.nomFormControl
  });

  constructor(public dialogRef: MatDialogRef<TypeRegroupementFormComponent>,
    @Inject(MAT_DIALOG_DATA) public typeRegroupementUpdate: TypeRegroupementUpdate) {
    this.typeRegroupementForm.patchValue({
      nom: typeRegroupementUpdate.nom
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.typeRegroupementUpdate.nom = this.nomFormControl.value;
  }
}
