import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-swipeable-button',
  templateUrl: './swipeable-button.component.html',
  styleUrls: ['./swipeable-button.component.css']
})
export class SwipeableButtonComponent implements OnInit {

  @Input()
  entity: any;

  @Output()
  onNonSwipeClick: EventEmitter<any> = new EventEmitter();

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  constructor() { }

  ngOnInit() {
  }

  swipe(event: TouchEvent, when: string): void {
    const coord: [number, number] = [event.changedTouches[0].clientX, event.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
      event.stopPropagation();
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (Math.abs(direction[0]) < 30 && Math.abs(direction[1]) < 30) {
        this.action();
      }
      this.stopEvent(event);
    }
  }

  action() {
    this.onNonSwipeClick.emit(this.entity);
  }

  stopEvent(event: any) {
    if (event.cancelable) {
      event.preventDefault();
    }
    event.stopPropagation();
  }

}
