import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrdreUpdate } from '../../models/ordre';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { TypeRegroupement, TypeRegroupementUpdate } from '../../models/type-regroupement';
import { TypeControleService } from './type-controle.service';
import { Pageable, Page } from '../../models/page';
import { map } from 'rxjs/operators';
import { TypeControle } from '../../models/type-controle';
import { TypePointDeControle } from '../../models/type-point-de-controle';

/**
 * Type Regroupement Service.
 */
@Injectable({
  providedIn: 'root'
})
export class TypeRegroupementService {

  /** Type de regroupement sélectionné */
  public selectedTypeRegroupementSubject: BehaviorSubject<TypeRegroupement> = new BehaviorSubject<TypeRegroupement>(undefined);

  /** Liste des types de points de controle */
  public typePointDeControleListSubject: BehaviorSubject<TypePointDeControle[]> = new BehaviorSubject<TypePointDeControle[]>(undefined);

  constructor(private http: HttpClient, private typeControleService: TypeControleService) { }

  public patchOrdre(ordreUpdateList: OrdreUpdate[]): Observable<void> {
    return this.http.patch<void>(environment.baseUrl + environment.typeRegroupementApi, ordreUpdateList);
  }

  public putTypeRegroupement(typeRegroupementUpdate: TypeRegroupementUpdate): Observable<TypeRegroupement> {
    return this.http.put<TypeRegroupement>(environment.baseUrl + environment.typeRegroupementApi, typeRegroupementUpdate)
      .pipe(
        map((typeRegroupement: TypeRegroupement) => this.refreshTypeRegroupementPage(typeRegroupement))
      );
  }

  public postTypeRegroupement(typeRegroupementUpdate: TypeRegroupementUpdate): Observable<TypeRegroupement> {
    return this.http.post<TypeRegroupement>(environment.baseUrl + environment.typeRegroupementApi, typeRegroupementUpdate)
      .pipe(
        map((typeRegroupement: TypeRegroupement) => this.refreshTypeRegroupementPage(typeRegroupement))
      );
  }

  public deleteTypeRegroupement(typeRegroupement: TypeRegroupement): Observable<TypeRegroupement> {
    return this.http.delete<TypeRegroupement>(environment.baseUrl + environment.typeRegroupementApi + "/" + typeRegroupement.id)
      .pipe(
        map((typeRegroupement: TypeRegroupement) => this.refreshTypeRegroupementPage(typeRegroupement))
      );
  }


  public getTypePointDeControleList(typeRegroupementId: number): Observable<TypePointDeControle[]> {
    return this.http.get<TypePointDeControle[]>(environment.baseUrl + environment.typeRegroupementApi + "/" + typeRegroupementId + "/typePointDeControles")
      .pipe(
        map((typePointDeControleList: TypePointDeControle[]) => {
          this.typePointDeControleListSubject.next(typePointDeControleList);
          return typePointDeControleList;
        })
      );
  }

  private refreshTypeRegroupementPage(typeRegroupement: TypeRegroupement): TypeRegroupement {
    let pageable: Pageable = this.typeControleService.typeRegroupementPageSubject.value.pageable;
    let typeControle: TypeControle = this.typeControleService.selectedTypeControleSubject.value;
    this.typeControleService.getTypeRegroupementByTypeControlePage(typeControle.id, pageable.pageNumber, pageable.pageSize).subscribe();
    return typeRegroupement;
  }

}
