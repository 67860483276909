import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FamilleCount, FamilleUpdate, Famille } from '../../models/famille-materiel';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { EntityListService } from './entity-list.service';

/**
 * Famille Service.
 */
@Injectable({
  providedIn: 'root'
})
export class FamilleService extends EntityListService {

  /** Liste des familles */
  public familleListSubject: BehaviorSubject<FamilleCount[]> = new BehaviorSubject<FamilleCount[]>([]);

  /** Famille sélectionnée */
  public selectedFamilleSubject: BehaviorSubject<Famille> = new BehaviorSubject<Famille>(undefined);

  constructor(private http: HttpClient) {
    super();
  }

  public getFamilleCountList(): Observable<FamilleCount[]> {
    return this.http.get<FamilleCount[]>(environment.baseUrl + environment.familleMaterielApi).pipe(
      map((typeControleList: FamilleCount[]) => {
        this.familleListSubject.next(typeControleList);
        return typeControleList;
      })
    );
  }

  public getFamille(id: number): Observable<Famille> {
    return this.http.get<Famille>(environment.baseUrl + environment.familleMaterielApi + "/" + id).pipe(
      map((famille: Famille) => {
        this.selectedFamilleSubject.next(famille);
        return famille;
      })
    );
  }

  public putFamille(familleUpdate: FamilleUpdate): Observable<FamilleCount> {
    return this.http.put<FamilleCount>(environment.baseUrl + environment.familleMaterielApi, familleUpdate)
      .pipe(
        map((familleCount: FamilleCount) => this.refreshItem(this.familleListSubject, familleCount))
      );
  }

  public postFamille(familleUpdate: FamilleUpdate): Observable<FamilleCount> {
    return this.http.post<FamilleCount>(environment.baseUrl + environment.familleMaterielApi, familleUpdate)
      .pipe(
        map((familleCount: FamilleCount) => this.addItem(this.familleListSubject, familleCount))
      );
  }

  public deleteFamille(familleUpdate: FamilleUpdate): Observable<FamilleCount> {
    return this.http.delete<FamilleCount>(environment.baseUrl + environment.familleMaterielApi + "/" + familleUpdate.id)
      .pipe(
        map((familleCount: FamilleCount) => {
          this.getFamilleCountList().subscribe();
          return familleCount;
        })
      );
  }

}
