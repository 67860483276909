import { Component, OnInit } from '@angular/core';
import { ControleFiltre } from 'src/app/models/controle';

@Component({
  selector: 'app-controle-export',
  templateUrl: './controle-export.component.html',
  styleUrls: ['./controle-export.component.css']
})
export class ControleExportComponent implements OnInit {

  controleFiltre: ControleFiltre;

  constructor() { }

  ngOnInit() {
  }

  onFiltre(controleFiltre: ControleFiltre) {
    this.controleFiltre = Object.assign({}, controleFiltre);
  }

}
