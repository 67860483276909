import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ControleService } from 'src/app/services/materiel/controle.service';
import { Controle } from 'src/app/models/controle';
import { MaterielSecuriteService } from 'src/app/services/materiel/materiel-securite.service';
import { MaterielSecurite } from 'src/app/models/materiel-securite';

/**
 * Controle Component.
 */
@Component({
  selector: 'app-controle',
  templateUrl: './controle.component.html',
  styleUrls: ['./controle.component.css']
})
export class ControleComponent implements OnInit {

  controle: Controle;

  materielSecurite: MaterielSecurite;

  constructor(private router: Router, private controleService: ControleService, private materielSecuriteService: MaterielSecuriteService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.controle = this.controleService.selectedControleSubject.value;
    if (!this.controle) {
      this.router.navigate(['../../'], { relativeTo: this.route })
    }
    this.materielSecurite = this.materielSecuriteService.materielSecuriteSubject.value;
  }

}
