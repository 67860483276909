import { StatutControleType } from './point-de-controle';
import { Utilisateur } from './utilisateur';
import { MaterielSecurite } from './materiel-securite';

/** Controle. */
export interface Controle {
    id: number;
    statut: string;
    commentaire: string;
    actif: boolean;
    controleAEffectuer: ControleAEffectuer;
    canValide: boolean;
    modifiePar: string;
    dateModification: Date;
    signature: string;
};

/** ControleAEffectuer. */
export interface ControleAEffectuer {
    idNiveauUtilisation: number;
    idTypeControleAffaire: number;
    idMaterielSecurite: number;
    nom: string;
};

/** ControleDetail. */
export interface ControleDetail {
    id: number;
    nom: string;
    statut: StatutControleType;
    modifiedBy: Utilisateur;
    updatedDate: Date;
    materielSecurite: MaterielSecurite;
};

/** ControleIdList. */
export class ControleIdList {
    controleIdList: number[];

    constructor(controleIdList: number[]) {
        this.controleIdList = controleIdList;
    };
}

/** ControleFiltre. */
export class ControleFiltre {
    codeMaquette: string;
    nomAffaire: string;
    statut: string;
    idFamille: number;
    designation: string;
    nomControle: string;
}