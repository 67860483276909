import { Component, OnInit, Input, HostListener } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { UtilisateurService } from 'src/app/services/common/utilisateur.service';
import { Utilisateur } from 'src/app/models/utilisateur';
import { MatDialog } from '@angular/material';
import { UtilisateurDetailComponent } from '../utilisateur-detail/utilisateur-detail.component';

/**
 * Top Bar Component.
 */
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  @Input()
  sidenav: MatSidenav;

  utilisateur: Utilisateur;

  innerWidth: number;

  constructor(private utilisateurService: UtilisateurService, public dialog: MatDialog) { }

  ngOnInit() {
    this.utilisateurService.selectedUtilisateurSubject.subscribe((utilisateur: Utilisateur) => {
      this.utilisateur = utilisateur;
    });
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  openDialog(): void {
    this.dialog.open(UtilisateurDetailComponent, {
      width: '200px'
    });
  }

}
