import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterielSecuriteComponent } from './components/materiel/materiel-securite/materiel-securite.component';
import { TopBarComponent } from './components/common/top-bar/top-bar.component';
import { MaterielSecuriteDetailComponent } from './components/materiel/materiel-securite-detail/materiel-securite-detail.component';
import { ControleListComponent } from './components/materiel/controle-list/controle-list.component';
import { PointDeControleListComponent } from './components/materiel/point-de-controle-list/point-de-controle-list.component';
import { LoadingBarComponent } from './components/common/loading-bar/loading-bar.component';
import { HttpRequestInterceptor } from './http-request-interceptor';
import { MaterielViewComponent } from './components/materiel/materiel-view/materiel-view.component';
import { MaterielIdentificationComponent } from './components/materiel/materiel-identification/materiel-identification.component';
import { MaterielNotificationListComponent } from './components/materiel/materiel-notification-list/materiel-notification-list.component';
import { PlanListComponent } from './components/plan/plan-list.component';
import { InputPhotoComponent } from './components/materiel/photo/input-photo/input-photo.component';
import { AdminViewComponent } from './components/admin/admin-view/admin-view.component';
import { TypeControleListComponent } from './components/admin/type-controle-list/type-controle-list.component';
import { FamilleListComponent } from './components/admin/famille-list/famille-list.component';
import { FamilleFormComponent } from './components/admin/famille-form/famille-form.component';
import { FamilleComponent } from './components/admin/famille/famille.component';
import { MatPaginatorIntlFr } from './paginator';
import { TypeControleComponent } from './components/admin/type-controle/type-controle.component';
import { TypeControleFormComponent } from './components/admin/type-controle-form/type-controle-form.component';
import { BooleanPipe } from './boolean-pipe';
import { TypePointDeControleComponent } from './components/admin/type-point-de-controle/type-point-de-controle.component';
import { TypeRegroupementListComponent } from './components/admin/type-regroupement-list/type-regroupement-list.component';
import { TypeRegroupementFormComponent } from './components/admin/type-regroupement-form/type-regroupement-form.component';
import { TypePointDeControleListComponent } from './components/admin/type-point-de-controle-list/type-point-de-controle-list.component';
import { TypePointDeControleFormComponent } from './components/admin/type-point-de-controle-form/type-point-de-controle-form.component';
import { DefaultInlineListPipe } from './default-inline-list-pipe';
import { FiliereFormComponent } from './components/admin/filiere-form/filiere-form.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { SignaturePadModule } from 'angular2-signaturepad';

import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { UtilisateurDetailComponent } from './components/common/utilisateur-detail/utilisateur-detail.component';
import { PointDeControleDetailComponent } from './components/materiel/point-de-controle-detail/point-de-controle-detail.component';
import { PhotoListComponent } from './components/materiel/photo/photo-list/photo-list.component';
import { PhotoControleFormComponent } from './components/materiel/photo-controle-form/photo-controle-form.component';
import { ControleExportListComponent } from './components/admin/controle-export-list/controle-export-list.component';
import { ControleExportComponent } from './components/admin/controle-export/controle-export.component';
import { ControleExportFiltreComponent } from './components/admin/controle-export-filtre/controle-export-filtre.component';
import { StatutControleTypeComponent } from './components/common/statut-controle-type/statut-controle-type.component';
import { ControleComponent } from './components/materiel/controle/controle.component';
import { InputSignatureComponent } from './components/materiel/input-signature/input-signature.component';
import { MatTreeModule } from '@angular/material/tree';
import { AideGlobaleComponent } from './components/common/aide-globale/aide-globale.component';
import { MaterielSecuriteItemListComponent } from './components/materiel/materiel-securite-item-list/materiel-securite-item-list.component';
import { SwipeableButtonComponent } from './components/common/swipable-button/swipeable-button.component';
import { PhotoContainerComponent } from './components/materiel/photo/photo-container/photo-container.component';

@NgModule({
  declarations: [
    AppComponent,
    MaterielSecuriteComponent,
    TopBarComponent,
    MaterielSecuriteDetailComponent,
    ControleListComponent,
    PointDeControleListComponent,
    LoadingBarComponent,
    MaterielViewComponent,
    MaterielIdentificationComponent,
    MaterielNotificationListComponent,
    InputPhotoComponent,
    AdminViewComponent,
    TypeControleListComponent,
    FamilleListComponent,
    FamilleFormComponent,
    FamilleComponent,
    TypeControleComponent,
    TypeControleFormComponent,
    BooleanPipe,
    DefaultInlineListPipe,
    TypePointDeControleComponent,
    TypeRegroupementListComponent,
    TypeRegroupementFormComponent,
    TypePointDeControleListComponent,
    TypePointDeControleFormComponent,
    FiliereFormComponent,
    UtilisateurDetailComponent,
    PointDeControleDetailComponent,
    PhotoListComponent,
    PhotoControleFormComponent,
    ControleExportListComponent,
    ControleExportComponent,
    ControleExportFiltreComponent,
    StatutControleTypeComponent,
    ControleComponent,
    InputSignatureComponent,
    AideGlobaleComponent,
    MaterielSecuriteItemListComponent,
    SwipeableButtonComponent,
    PhotoContainerComponent,
    PlanListComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatCardModule,
    MatGridListModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatTableModule,
    MatBadgeModule,
    MatRippleModule,
    MatIconModule,
    MatDividerModule,
    MatInputModule,
    MatSortModule,
    MatDialogModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatPaginatorModule,
    DragDropModule,
    CommonModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTabsModule,
    MatSelectModule,
    SignaturePadModule,
    MatTreeModule,
    MatProgressSpinnerModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.baseUrl],
        sendAccessToken: false
      }
    }),
    RouterModule.forRoot([
      {
        path: 'admin', component: AdminViewComponent, children:
          [
            { path: '', component: ControleExportComponent },
            { path: 'controleExport', component: ControleExportComponent },
            { path: 'familleMateriel', component: FamilleComponent },
            { path: 'familleMateriel/:familleMaterielId/typeControles', component: TypeControleComponent },
            { path: 'familleMateriel/:familleMaterielId/typeControles/:typeControleId/typePointDeControles', component: TypePointDeControleComponent }
          ]
      },
      {
        path: 'materiel-securite/:codeAffaire/:codePhase/:codeBible/:designation/:module', component: MaterielViewComponent, children:
          [
            { path: '', component: MaterielSecuriteComponent },
            { path: 'controle/:controleId', component: ControleComponent }
          ]
      }
    ])
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr }
],
  bootstrap: [AppComponent],
  entryComponents: [
    FamilleFormComponent,
    TypeControleFormComponent,
    TypeRegroupementFormComponent,
    TypePointDeControleFormComponent,
    FiliereFormComponent,
    UtilisateurDetailComponent,
    PointDeControleDetailComponent,
    PhotoControleFormComponent
  ]
})
export class AppModule { }
