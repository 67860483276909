import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aide-globale',
  templateUrl: './aide-globale.component.html',
  styleUrls: ['./aide-globale.component.css']
})
export class AideGlobaleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
