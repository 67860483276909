import { Component, OnInit } from '@angular/core';
import { FamilleUpdate, FamilleCount } from 'src/app/models/famille-materiel';
import { MatDialog } from '@angular/material/dialog';
import { FamilleService } from 'src/app/services/admin/famille.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FamilleFormComponent } from '../famille-form/famille-form.component';

/**
 * Famille Component.
 */
@Component({
  selector: 'app-famille',
  templateUrl: './famille.component.html',
  styleUrls: ['./famille.component.css']
})
export class FamilleComponent implements OnInit {

  constructor(private familleService: FamilleService, public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FamilleFormComponent, {
      width: '500px',
      data: {
        readOnly: false,
        familleUpdate: new FamilleUpdate()
      }
    });

    dialogRef.afterClosed().subscribe((familleUpdate: FamilleUpdate) => {
      if (familleUpdate) {
        this.familleService.postFamille(familleUpdate).subscribe((updatedFamille: FamilleCount) => {
          this._snackBar.open("Famille " + updatedFamille.nom + " créée", "OK");
        });
      }
    });
  }

}
