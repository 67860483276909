import { Injectable } from '@angular/core';
import { Subject, Subscription, Observable, BehaviorSubject } from 'rxjs';
import { Controle } from '../../models/controle';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TypeRegroupementAffaire } from '../../models/type-regroupement-affaire';
import { PointDeControle, PointDeControleRegroupement } from '../../models/point-de-controle';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Controle Service.
 */
@Injectable({
  providedIn: 'root'
})
export class ControleService {

  /** Controle sélectionné */
  selectedControleSubject: BehaviorSubject<Controle> = new BehaviorSubject<Controle>(undefined);

  /** Liste des controles */
  controleList: Subject<Controle[]> = new Subject<Controle[]>();

  /** Liste des points de controle par regroupement */
  pointDeControleRegroupementListSubject: Subject<PointDeControleRegroupement[]> = new Subject<PointDeControleRegroupement[]>();

  /** Liste des points de controle à valider automatiquement */
  pointDeControleAutoValidationListSubject: BehaviorSubject<PointDeControle[]> = new BehaviorSubject<PointDeControle[]>([]);

  constructor(private http: HttpClient) { }

  select(selectedControle: Controle) {
    this.selectedControleSubject.next(selectedControle);
  }

  loadControleByMaterielIdList(idMateriel: number): Subscription {
    let api = environment.controleByMaterielIdListApi.replace(":idMateriel", "" + idMateriel);
    return this.http.get<Controle[]>(environment.baseUrl + api)
      .subscribe((controleList: Controle[]) => this.controleList.next(controleList));
  }

  /**
   * Chargement des points de controles par regroupement.
   * @param controle Controle
   */
  public loadPointDeControleRegroupementList(controle: Controle): Subscription {
    return this.http.get<PointDeControleRegroupement[]>(environment.baseUrl + environment.controleApi + "/" + controle.id + "/regroupements")
      .subscribe(pointDeControleRegroupementList => this.initPointDeControleSubjects(pointDeControleRegroupementList));
  }

  private initPointDeControleSubjects(pointDeControleRegroupementList: PointDeControleRegroupement[]): void {
    const pointDeControleRegroupementListFilter: PointDeControleRegroupement[] = [];
    let pointDeControleAutoValideList: PointDeControle[] = [];

    pointDeControleRegroupementList.forEach(pointDeControleRegroupement => {
      // Récupération des points de controles auto validés
      pointDeControleAutoValideList = pointDeControleAutoValideList
        .concat(pointDeControleRegroupement.pointDeControleList.filter((pointDeControle: PointDeControle) =>
          this.defaultValidationPrecondition(pointDeControle)));

      // Filtrage des valeurs Vides et Non
      const pointDeControleListFilter = pointDeControleRegroupement.pointDeControleList
        .filter((pointDeControle: PointDeControle) => !this.defaultValidationPrecondition(pointDeControle));

      const pointDeControleRegroupementFilter = new PointDeControleRegroupement();
      pointDeControleRegroupementFilter.pointDeControleList = pointDeControleListFilter;
      pointDeControleRegroupementFilter.typeRegroupementAffaire = pointDeControleRegroupement.typeRegroupementAffaire;
      pointDeControleRegroupementListFilter.push(pointDeControleRegroupementFilter);
    });

    this.pointDeControleAutoValidationListSubject.next(pointDeControleAutoValideList);
    this.pointDeControleRegroupementListSubject.next(pointDeControleRegroupementListFilter);
  }

  private defaultValidationPrecondition(pointDeControle: PointDeControle): boolean {
    return ((pointDeControle.valeurChaineCaractereCible == '' || pointDeControle.valeurChaineCaractereCible == 'Non')
      && pointDeControle.valeurNombreCible === null) || pointDeControle.valeurNombreCible === 0;
  }

}
