/** TypePointDeControle. */
export interface TypePointDeControle {
    id: number;
    nom: string;
    ordre: number;
    codeCaracteristique: string;
    idTypeRegroupement: number;
}

/** TypePointDeControleUpdate. */
export class TypePointDeControleUpdate {
    id: number;
    nom: string;
    ordre: number;
    codeCaracteristique: string;
    idTypeRegroupement: number;

    static to(typePointDeControle: TypePointDeControle): TypePointDeControleUpdate {
        let typePointDeControleUpdate = new TypePointDeControleUpdate();
        typePointDeControleUpdate.id = typePointDeControle.id;
        typePointDeControleUpdate.nom = typePointDeControle.nom;
        typePointDeControleUpdate.ordre = typePointDeControle.ordre;
        typePointDeControleUpdate.codeCaracteristique = typePointDeControle.codeCaracteristique;
        typePointDeControleUpdate.idTypeRegroupement = typePointDeControle.idTypeRegroupement;
        return typePointDeControleUpdate;
    }
}