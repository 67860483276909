/** Famille. */
export class Famille {
    id: number;
    nom: string;
    aideUrl: string;

    constructor() { }
};

/** FamilleCount. */
export interface FamilleCount {
    id: number;
    nom: string;
    codeDesignation: string;
    codeModule: string;
    codeNiveaux: string;
    nombreMateriel: number;
    aideUrl: string;
}

/** FamilleUpdate. */
export class FamilleUpdate {
    id: number;
    nom: string;
    codeDesignation: string;
    codeModule: string;
    codeNiveaux: string;
    aideUrl: string;

    constructor() { }

    static to(familleCount: FamilleCount): FamilleUpdate {
        let familleUpdate = new FamilleUpdate();
        familleUpdate.id = familleCount.id;
        familleUpdate.nom = familleCount.nom;
        familleUpdate.codeDesignation = familleCount.codeDesignation;
        familleUpdate.codeModule = familleCount.codeModule;
        familleUpdate.codeNiveaux = familleCount.codeNiveaux;
        familleUpdate.aideUrl = familleCount.aideUrl;

        return familleUpdate;
    }
}