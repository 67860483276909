import { Component, OnInit } from '@angular/core';

/**
 * Matériel View Component - Conteneur d'affichage de la page Matériel (incl. Sidenav).
 */
@Component({
  templateUrl: './materiel-view.component.html',
  styleUrls: ['./materiel-view.component.css']
})
export class MaterielViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
