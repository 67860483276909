import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { TypeControle, TypeControleUpdate } from '../../models/type-controle';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Page, Pageable } from '../../models/page';
import { TypeRegroupement } from '../../models/type-regroupement';
import { OrdreUpdate } from '../../models/ordre';

/**
 * Type de Controle Service.
 */
@Injectable({
  providedIn: 'root'
})
export class TypeControleService {

  /** Types de controle paginés */
  public typeControlePageSubject: BehaviorSubject<Page<TypeControle>> = new BehaviorSubject<Page<TypeControle>>(new Page<TypeControle>());

  /** Type de controle sélectionné */
  public selectedTypeControleSubject: BehaviorSubject<TypeControle> = new BehaviorSubject<TypeControle>(undefined);

  /** Types de regroupement paginés */
  public typeRegroupementPageSubject: BehaviorSubject<Page<TypeRegroupement>> = new BehaviorSubject<Page<TypeRegroupement>>(new Page<TypeRegroupement>());

  constructor(private http: HttpClient) { }

  public getTypeControle(typeControleId: number): Observable<TypeControle> {
    return this.http.get<TypeControle>(environment.baseUrl + environment.typeControleApi + "/" + typeControleId).pipe(
      map((typeControle: TypeControle) => {
        this.selectedTypeControleSubject.next(typeControle);
        return typeControle;
      })
    );
  }

  public getTypeControlePage(familleId: number, page: number, size: number): Observable<Page<TypeControle>> {
    let pagination = "?page=" + page + "&size=" + size;
    let api = environment.familleMaterielTypeControleApi.replace(":idFamille", "" + familleId);
    return this.http.get<Page<TypeControle>>(environment.baseUrl + api + pagination).pipe(
      map((typeControleList: Page<TypeControle>) => {
        this.typeControlePageSubject.next(typeControleList);
        return typeControleList;
      })
    );
  }

  public patchOrdre(typeControleOrdreUpdateList: OrdreUpdate[]): Observable<void> {
    return this.http.patch<void>(environment.baseUrl + environment.typeControleApi, typeControleOrdreUpdateList);
  }

  public putTypeControle(typeControleUpdate: TypeControleUpdate): Observable<TypeControle> {
    return this.http.put<TypeControle>(environment.baseUrl + environment.typeControleApi, typeControleUpdate)
      .pipe(
        map((typeControle: TypeControle) => this.refreshItem(typeControle))
      );
  }

  public postTypeControle(typeControleUpdate: TypeControleUpdate): Observable<TypeControle> {
    return this.http.post<TypeControle>(environment.baseUrl + environment.typeControleApi, typeControleUpdate)
      .pipe(
        map((typeControle: TypeControle) => this.refreshList(typeControle))
      );
  }

  public deleteTypeControle(typeControle: TypeControle): Observable<TypeControle> {
    return this.http.delete<TypeControle>(environment.baseUrl + environment.typeControleApi + "/" + typeControle.id)
      .pipe(
        map((typeControle: TypeControle) => this.refreshList(typeControle))
      );
  }

  public getTypeRegroupementByTypeControlePage(typeControleId: number, page: number, size: number): Observable<Page<TypeRegroupement>> {
    let pagination = "?page=" + page + "&size=" + size;
    return this.http.get<Page<TypeRegroupement>>(environment.baseUrl + environment.typeControleApi + "/" + typeControleId + "/typeRegroupements" + pagination)
      .pipe(
        map((typeRegroupementPage: Page<TypeRegroupement>) => {
          this.typeRegroupementPageSubject.next(typeRegroupementPage);
          return typeRegroupementPage;
        })
      );
  }

  public postFiliereList(typeControleId: number, filiereIdList: number[]): Observable<TypeControle> {
    return this.http.post<TypeControle>(environment.baseUrl + environment.typeControleApi + "/" + typeControleId + "/filieres", filiereIdList)
      .pipe(
        map((typeControle: TypeControle) => this.refreshItem(typeControle))
      );
  }

  private refreshList(typeControle: TypeControle): TypeControle {
    let pageable: Pageable = this.typeControlePageSubject.value.pageable;
    this.getTypeControlePage(typeControle.famille.id, pageable.pageNumber, pageable.pageSize).subscribe();
    return typeControle;
  }

  private refreshItem(typeControleAction: TypeControle): TypeControle {
    const typeControlePage: Page<TypeControle> = this.typeControlePageSubject.value;
    typeControlePage.content = typeControlePage.content.map(typeControle => (typeControle.id === typeControleAction.id) ? typeControleAction : typeControle);
    this.typeControlePageSubject.next(typeControlePage);
    return typeControleAction;
  }

}
