import { TypeRegroupementAffaire } from './type-regroupement-affaire';

/** PointDeControle. */
export interface PointDeControle {
    id: number;
    statut: StatutControleType;
    valeurChaineCaractereCible: string;
    valeurNombreCible: number;
    actif: boolean;
    commentaire: string;
    typePointDeControleAffaire: TypePointDeControleAffaire;
};

/**
 * TypeRegroupementAffaire.
 * Utilisé pour récupérer la liste des controles par type de regroupement.
 */
export class PointDeControleRegroupement {
    typeRegroupementAffaire: TypeRegroupementAffaire;
    pointDeControleList: PointDeControle[];

    constructor() { }
}

/** TypePointDeControleAffaire. */
export interface TypePointDeControleAffaire {
    id: number;
    nom: string;
    ordre: number;
    codeCaracteristique: string;
    actif: boolean;
};

/** ValidationPointDeControle. */
export class ValidationPointDeControle {
    id: number;
    statut: StatutControleType;

    constructor(id: number, statut: StatutControleType) {
        this.id = id;
        this.statut = statut;
    }
};

/** Validation. */
export class Validation {
    commentaire: string;
    validationPointDeControleList: ValidationPointDeControle[];

    constructor(commentaire: string, validationPointDeControleList: ValidationPointDeControle[]) {
        this.commentaire = commentaire;
        this.validationPointDeControleList = validationPointDeControleList;
    }
}

/** PointDeControleUpdate. */
export class PointDeControleUpdate {
    id: number;
    commentaire: string;

    constructor(id: number, commentaire: string) {
        this.id = id;
        this.commentaire = commentaire;
    }
};

/** StatutControleType. */
export enum StatutControleType {
    INIT = "INIT", OK = "OK", NON_CONTROLE = "NON_CONTROLE", NON_VALIDE = "NON_VALIDE"
}
