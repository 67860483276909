import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Photo } from 'src/app/models/photo';
import { PhotoService } from 'src/app/services/materiel/photo/photo.service';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';

/**
 * Photo Container.
 */
@Component({
  selector: 'app-photo-container',
  templateUrl: './photo-container.component.html',
  styleUrls: ['./photo-container.component.css']
})
export class PhotoContainerComponent implements OnInit, OnDestroy {

  /** Id Controle / Point de controle. */
  @Input()
  selectedId: number;

  @Input()
  photoService: PhotoService;

  photoList: Photo[] = [];

  uploadedFile: File;

  uploadInProgress: boolean = false;

  photoListSubs: Subscription;

  constructor(private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.photoService.getPhotoList(this.selectedId).subscribe();
    this.photoListSubs = this.photoService.photoListSubject.subscribe((photoList: Photo[]) => this.photoList = photoList);
  }

  ngOnDestroy(): void {
    this.photoListSubs.unsubscribe();
  }

  onValidatedUpload(): void {
    this.uploadInProgress = true;
    this.photoService.postPhoto(this.selectedId, this.uploadedFile).subscribe(
      () => {
        this._snackBar.open("Photographie " + this.uploadedFile.name + " ajoutée", "OK");
        this.uploadedFile = undefined;
        this.uploadInProgress = false;
      },
      () => this.uploadInProgress = false);
  }

  onPhotoDelete(photo: Photo) {
    this.photoService.deletePhoto(photo.id).subscribe(() => {
      this._snackBar.open("Photographie " + photo.nom + " supprimée", "OK");
    });
  }

}
