/** Photo. */
export interface Photo {
    id: number;
    nom: string;
    contenu: string;
}

/** PhotoAffichage. */
export class PhotoAffichage {
    id: number;
    nom: string;
    image: string | ArrayBuffer;
}