import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MaterielSecuriteService } from '../../../services/materiel/materiel-securite.service';
import { MaterielSecurite } from '../../../models/materiel-securite';
import { Subscription } from 'rxjs';

/**
 * Materiel Securite Component - Conteneur d'affichage du matériel.
 */
@Component({
  selector: 'app-materiel-securite',
  templateUrl: './materiel-securite.component.html',
  styleUrls: ['./materiel-securite.component.css']
})
export class MaterielSecuriteComponent implements OnInit, OnDestroy {

  materielSecurite: MaterielSecurite;

  materielSubs: Subscription;

  constructor(private route: ActivatedRoute, private materielSecuriteService: MaterielSecuriteService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let codeAffaire = params.get('codeAffaire');
      let codePhase = params.get('codePhase');
      let codeBible = params.get('codeBible');
      let designation = params.get('designation');
      let module = params.get('module');

      this.materielSecuriteService.loadMaterielSecurite(codeAffaire, codePhase, codeBible, designation, module);

      this.materielSubs = this.materielSecuriteService.materielSecuriteSubject
        .subscribe((materielSecurite: MaterielSecurite) => this.materielSecurite = materielSecurite);
    });
  }

  ngOnDestroy(): void {
    this.materielSubs.unsubscribe();
  }

}
