import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ValidationPointDeControle, PointDeControle, StatutControleType, Validation } from '../../models/point-de-controle';
import { HttpClient } from '@angular/common/http';
import { Controle } from '../../models/controle';
import { ControleService } from './controle.service';
import { Observable, forkJoin, Subject } from 'rxjs';

/**
 * Validation Service.
 */
@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private http: HttpClient, private controleService: ControleService) { }

  postValidation(idControle: number, commentaire: string, pointDeControleList: PointDeControle[], signature: Blob): Observable<Controle> {
    let result = new Subject<Controle>();
    let api = environment.controleApi + "/" + idControle;

    let apiValidation = api + "/validation";
    var postValidation: Observable<Controle> = this.http.post<Controle>(environment.baseUrl + apiValidation, new Validation(commentaire, this.getValidationPointDeControleList(pointDeControleList)));


    if (signature) {
      const formData: FormData = new FormData();
      formData.append('signature', signature);
      var postSignature: Observable<Controle> = this.http.post<Controle>(environment.baseUrl + api + "/signature", formData);

      // Validation + Signature
      postValidation.subscribe(() => postSignature.subscribe(controle => result.next(controle)));
    } else {
      // Validation
      postValidation.subscribe(controle => result.next(controle));
    }

    return result;
  }

  postPreValidation(idControle: number, commentaire: string, pointDeControleList: PointDeControle[], signature: Blob): Observable<Controle> {
    let result = new Subject<Controle>();
    let api = environment.controleApi + "/" + idControle;

    let apiPreValidation = api + "/preValidation";
    var postPreValidation: Observable<Controle> = this.http.post<Controle>(environment.baseUrl + apiPreValidation, new Validation(commentaire, this.getValidationPointDeControleList(pointDeControleList)));


    if (signature) {
      const formData: FormData = new FormData();
      formData.append('signature', signature);
      var postSignature: Observable<Controle> = this.http.post<Controle>(environment.baseUrl + api + "/signature", formData);

      // PreValidation + Signature
      postPreValidation.subscribe(() => postSignature.subscribe(controle => result.next(controle)));
    } else {
      // PreValidation
      postPreValidation.subscribe(controle => result.next(controle));
    }

    return result;
  }

  private getValidationPointDeControleList(pointDeControleList: PointDeControle[]): ValidationPointDeControle[] {
    let validationPointDeControleList: ValidationPointDeControle[] = [];

    this.controleService.pointDeControleAutoValidationListSubject.value.forEach((pointDeControle: PointDeControle) => {
      validationPointDeControleList.push(new ValidationPointDeControle(pointDeControle.id, StatutControleType.OK));
    });

    pointDeControleList.forEach((pointDeControle: PointDeControle) => {
      validationPointDeControleList.push(new ValidationPointDeControle(pointDeControle.id, (pointDeControle.statut === StatutControleType.INIT ? StatutControleType.NON_VALIDE : pointDeControle.statut)));
    });

    return validationPointDeControleList;
  }

}
