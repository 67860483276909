import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TypeControleService } from 'src/app/services/admin/type-controle.service';
import { FamilleService } from 'src/app/services/admin/famille.service';
import { Subscription } from 'rxjs';
import { Famille } from 'src/app/models/famille-materiel';
import { TypeControle } from 'src/app/models/type-controle';
import { TypeRegroupementUpdate, TypeRegroupement } from 'src/app/models/type-regroupement';
import { MatDialog, MatSnackBar } from '@angular/material';
import { TypeRegroupementFormComponent } from '../type-regroupement-form/type-regroupement-form.component';
import { TypeRegroupementService } from 'src/app/services/admin/type-regroupement.service';

/**
 * Type Point de Controle Component.
 */
@Component({
  selector: 'app-type-point-de-controle',
  templateUrl: './type-point-de-controle.component.html',
  styleUrls: ['./type-point-de-controle.component.css']
})
export class TypePointDeControleComponent implements OnInit, OnDestroy {

  selectedFamilleId: number;

  famille: Famille;

  familleSubscription: Subscription;

  selectedTypeControleId: number;

  typeControle: TypeControle;

  typeControleSubscription: Subscription;

  constructor(private route: ActivatedRoute, private typeControleService: TypeControleService, private familleService: FamilleService,
    public dialog: MatDialog, private typeRegroupementService: TypeRegroupementService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.selectedFamilleId = +params.get('familleMaterielId');
      this.selectedTypeControleId = +params.get('typeControleId');
    });

    // Récupération de la famille
    this.familleSubscription = this.familleService.selectedFamilleSubject.subscribe((famille: Famille) => {
      if (famille && famille.id === this.selectedFamilleId) {
        this.famille = famille;
      } else {
        this.familleService.getFamille(this.selectedFamilleId).subscribe();
      }
    });

    // Récupération du type de controle
    this.typeControleSubscription = this.typeControleService.selectedTypeControleSubject.subscribe((typeControle: TypeControle) => {
      if (typeControle && typeControle.id === this.selectedTypeControleId) {
        this.typeControle = typeControle;
      } else {
        this.typeControleService.getTypeControle(this.selectedTypeControleId).subscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this.familleSubscription.unsubscribe();
    this.typeControleSubscription.unsubscribe();
  }

  openDialog(): void {
    let typeRegroupementUpdate = new TypeRegroupementUpdate();
    typeRegroupementUpdate.idTypeControle = this.selectedTypeControleId;
    const dialogRef = this.dialog.open(TypeRegroupementFormComponent, {
      width: '500px',
      data: typeRegroupementUpdate
    });

    dialogRef.afterClosed().subscribe((typeRegroupementUpdate: TypeRegroupementUpdate) => {
      if (typeRegroupementUpdate) {
        this.typeRegroupementService.postTypeRegroupement(typeRegroupementUpdate).subscribe((typeRegroupement: TypeRegroupement) => {
          this._snackBar.open("Type de regroupement " + typeRegroupement.nom + " créé", "OK");
        });
      }
    });
  }

}
