import { Component, OnInit, Input } from '@angular/core';
import { MaterielSecurite, Chantier } from '../../../models/materiel-securite';
import { MaterielSecuriteService } from 'src/app/services/materiel/materiel-securite.service';

/**
 * Matériel Sécurité Detail Component - Affichage des détails d'un matériel.
 */
@Component({
  selector: 'app-materiel-securite-detail',
  templateUrl: './materiel-securite-detail.component.html',
  styleUrls: ['./materiel-securite-detail.component.css']
})
export class MaterielSecuriteDetailComponent implements OnInit {

  @Input()
  materielSecurite: MaterielSecurite;

  chantier: Chantier;

  constructor(private materielSecuriteService: MaterielSecuriteService) { }

  ngOnInit() {
    // Chargement uniquement si pas de valeur
    if (!this.materielSecuriteService.chantierListSubject.value) {
      this.materielSecuriteService.getChantiers(this.materielSecurite.affaire.id).subscribe(chantierList => {
        if (chantierList.length > 0) {
          this.chantier = chantierList[0];
        }
      });
    } else {
      const chantierList = this.materielSecuriteService.chantierListSubject.value;
      if (chantierList.length > 0) {
        this.chantier = chantierList[0];
      }
    }
  }

}
