import { Component, OnInit } from '@angular/core';
import { MaterielSecuriteService } from 'src/app/services/materiel/materiel-securite.service';
import { MaterielSecurite, Chantier } from 'src/app/models/materiel-securite';

/**
 * Materiel Identification Component - Identification d'un matériel.
 */
@Component({
  selector: 'app-materiel-identification',
  templateUrl: './materiel-identification.component.html',
  styleUrls: ['./materiel-identification.component.css']
})
export class MaterielIdentificationComponent implements OnInit {

  materielSecurite: MaterielSecurite;

  chantierList: Chantier[];

  constructor(private materielService: MaterielSecuriteService) { }

  ngOnInit() {
    this.materielService.materielSecuriteSubject.subscribe((materielSecurite: MaterielSecurite) => this.materielSecurite = materielSecurite);
    this.materielService.chantierListSubject.subscribe(chantierList => this.chantierList = chantierList);
  }

}
