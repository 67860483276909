import { Component, OnInit, Input } from '@angular/core';
import { Plan } from 'src/app/models/plan';
import { MatSidenav } from '@angular/material';
import { MaterielSecuriteService } from 'src/app/services/materiel/materiel-securite.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-plan-list',
  templateUrl: './plan-list.component.html',
  styleUrls: ['./plan-list.component.css']
})
export class PlanListComponent implements OnInit {

  @Input()
  sidenav: MatSidenav;

  planList: Plan[] = [];

  planListDisplay: Plan[] = [];
  page: number = 0;
  size: number = 5;
  last: boolean = false;

  constructor(private materielSecuriteService: MaterielSecuriteService) { }

  ngOnInit() {
    this.materielSecuriteService.planListSubject.subscribe((planList: Plan[]) => {
      this.planList = planList;
      this.nextPage();
    });
  }

  nextPage() {
    let displayLength: number = (this.page + 1) * this.size;
    this.last = displayLength >= this.planList.length;
    this.planListDisplay = this.planList.slice(undefined, this.last ? undefined : displayLength);
    if (!this.last) {
      this.page += 1;
    }
  }

  openPlan(plan: Plan): void {
    window.open(environment.plansDriveBaseUrl.replace(":idDrive", plan.idDrive), '_blank').focus();
  }
  
  downloadPlan(plan: Plan): void {
    window.open(environment.plansDriveBaseUrl.replace(":idDrive", plan.idDrive).concat("&export=download"), "_self");
  }

}
