import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * Error Service - Affichage des messages d'erreurs HTTP.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  public errorSubject = new Subject<HttpErrorResponse>();

  constructor(private _snackBar: MatSnackBar) { 
    this.errorSubject.subscribe((error: HttpErrorResponse) => {
      if(typeof error.error === "string") {
        this._snackBar.open(error.error, "OK");
      } else {
        this._snackBar.open(error.message, "OK");
      }
        
    });
  }
}
