import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Utilisateur } from 'src/app/models/utilisateur';
import { map } from 'rxjs/operators';
import { TypeControle } from 'src/app/models/type-controle';

/**
 * Utilisateur Service.
 */
@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {

  public selectedUtilisateurSubject: BehaviorSubject<Utilisateur> = new BehaviorSubject<Utilisateur>(undefined);

  constructor(private http: HttpClient) { }

  public getUtilisateurConnecte(accessToken: string): Observable<Utilisateur> {
    // Envoi de la token (uniquement lors de cet appel, ensuite utilisation du cookie)
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + accessToken
      })
    };

    return this.http.get<Utilisateur>(environment.baseUrl + environment.utilisateurApi, httpOptions).pipe(
      map((utilisateur: Utilisateur) => {
        this.selectedUtilisateurSubject.next(utilisateur);
        return utilisateur;
      })
    );
  }

}
