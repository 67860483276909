import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { TypeControleFormComponent } from '../type-controle-form/type-controle-form.component';
import { TypeControleUpdate, TypeControle } from 'src/app/models/type-controle';
import { TypeControleService } from 'src/app/services/admin/type-controle.service';
import { FamilleService } from 'src/app/services/admin/famille.service';
import { Famille } from 'src/app/models/famille-materiel';
import { Subscription } from 'rxjs';

/**
 * Type Controle Component.
 */
@Component({
  selector: 'app-type-controle',
  templateUrl: './type-controle.component.html',
  styleUrls: ['./type-controle.component.css']
})
export class TypeControleComponent implements OnInit, OnDestroy {

  selectedFamilleId: number;

  famille: Famille;

  familleSubscription: Subscription;

  constructor(private route: ActivatedRoute, private typeControleService: TypeControleService, public dialog: MatDialog,
    private _snackBar: MatSnackBar, private familleService: FamilleService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.selectedFamilleId = +params.get('familleMaterielId');
    });
    this.familleSubscription = this.familleService.selectedFamilleSubject.subscribe((famille: Famille) => {
      if (famille && famille.id === this.selectedFamilleId) {
        this.famille = famille;
      } else {
        this.familleService.getFamille(this.selectedFamilleId).subscribe();
      }
    })
  }

  ngOnDestroy(): void {
    this.familleSubscription.unsubscribe();
  }

  openDialog(): void {
    let typeControleUpdate = new TypeControleUpdate();
    typeControleUpdate.idFamille = this.selectedFamilleId;
    const dialogRef = this.dialog.open(TypeControleFormComponent, {
      width: '500px',
      data: typeControleUpdate
    });

    dialogRef.afterClosed().subscribe((typeControleUpdate: TypeControleUpdate) => {
      if (typeControleUpdate) {
        this.typeControleService.postTypeControle(typeControleUpdate).subscribe((typeControle: TypeControle) => {
          this._snackBar.open("Type de controle " + typeControle.nom + " créé", "OK");
        });
      }
    });
  }

}
